.navbar {
  padding-top: $navbar-padding-base;
  padding-bottom: $navbar-padding-base;
  padding: 18px 20px;
  // min-height: 53px;
  // margin-bottom: 20px;

  .navbar-absolute {
    padding-top: 10px;
    background: blue !important;
  }

  .navbarsetting {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .main-head {
    float: left;
    width: 100%;
    position: relative;

    .main-search-image {
      position: absolute;
      top: 15px;
      left: 11px;
      width: 24px;
      height: 24px;
    }

    .main-search-input {
      background: #1e1e1e;
      border: 1px solid #292929;
      border-radius: 8px;
      padding: 15px 10px 15px 45px;
      width: 512px;
      // margin-top: 18px;
      color: #fafafa;

      &::placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        color: #4a4a4a;
      }
    }
  }

  .noti {
    .notifications {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 10px;
      background: #1e1e1e;
      border: 1px solid #292929;
      border-radius: 40px;
      width: 44px;
      height: 44px;
      margin-right: 15px;

      .notiimg {
        width: 24px;
        height: 24px;
      }

      .numimg {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 0px;
        right: 0px;
        background: #009de0;
        border: 1px solid #1e1e1e;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        color: #ffffff;
      }
    }

    .noti-inner {
      transform: translate(-89%, 30px) !important;
      margin-top: 20px;
      background: #151515;
      border: 1px solid #353535;
      box-shadow: 9px 10px 30px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      width: 382px;
      max-height: 554px;

      .upper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        border-bottom: 1px solid #292929;

        .upper-head {
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: 100%;
          color: #ffffff;
        }

        .upper-para {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          text-align: center;
          color: #009de0;
        }
      }

      .scrollss {
        overflow-y: scroll;
        width: 382px;
        max-height: 479px;

        /* width */
        &::-webkit-scrollbar {
          width: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #232323;
          border-radius: 20px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #009de0;
          border-radius: 20px;
        }

        .lower {
          display: flex;
          align-items: center;
          padding: 20px;
          border-bottom: 1px solid #292929;

          &:hover {
            background: #1e1e1e;
            cursor: pointer;
          }

          .lower-outer {
            width: 42px;
            height: 42px;
            background: #009de0;
            border: 1px solid #292929;
            border-radius: 40px;
            display: flex;
            justify-content: center;
            align-items: center;

            .lower-img {
              width: 24px;
              height: 24px;
            }
          }
        }

        .lower-inner {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          margin-left: 8px;

          .lower-head {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #ffffff;
            margin-bottom: 8px;
          }

          .btnf {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .lower-btn {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 4px 5px;
              background: #e15151;
              border-radius: 30px;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 100%;
              color: #ffffff;
              border: none;
            }

            .lower-para {
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 100%;
              color: #767676;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }

  .inner-head {
    display: flex;
    align-items: center;
    // margin-top: 18px;

    .nav-btn-log-out {
      background: #e15151;
      border: 1px solid #292929;
      border-radius: 40px;
      padding: 12px 45px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #ffffff;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 25px;

      .logout {
        margin-right: 12px;
      }
    }
  }

  .btn555 {
    padding: 10px 13px;
    width: 160px;
    height: 48px;
    border: 1px solid #1fa5ff;
    backdrop-filter: blur(20px);
    border-radius: 50px;
    background-color: transparent;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #ffffff;
    margin-right: 30px;
  }

  // .dropdown-menu.show {
  //     width: 286px !important;
  //     background: #0B0C22;
  //     border: 1px solid #30304A;
  //     border-radius: 8px;
  //     padding: 0% !important;

  //     .itm22 {
  //         padding: 0px !important;
  //     }

  //     .itm22:hover {
  //         background: #212241 !important;
  //     }

  //     .itm33 {
  //         padding: 11px 20px !important;
  //     }

  //     .itm33:hover {
  //         background: #212241 !important;
  //     }

  //     .img22 {
  //         margin-right: 10px !important;
  //     }
  // }

  a {
    vertical-align: middle;

    &:not(.btn):not(.dropdown-item) {
      color: $white-color;
    }

    &.dropdown-item {
      color: $default-color;
    }
  }

  &.bg-white {
    .input-group .form-control,
    .input-group.no-border .form-control {
      color: $default-color;

      // @include placeholder(){
      //   color: $default-color;
      // };
    }

    .input-group-prepend .input-group-text i,
    .input-group-append .input-group-text i {
      color: $default-color;
      opacity: 0.5;
    }
  }

  .form-group,
  .input-group {
    margin: 0;
    margin-left: -3px;
    //   margin-right: 5px;

    .form-group-addon,
    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text {
      color: $default-color;

      i {
        opacity: 1;
      }
    }

    &.no-border {
      &:hover {
        .form-control {
          box-shadow: 0 0.1rem 0.5rem rgb(194, 194, 199);
        }

        .input-group-append {
          .input-group-text {
            box-shadow: 0 0.1rem 0.5rem rgb(194, 194, 199);
          }
        }
      }

      .input-group-append {
        .input-group-text {
          background-color: #ffffff;
          border: none !important;
          border-radius: 0px 10px 10px 0px;

          &:hover {
            box-shadow: none;
          }
        }
      }

      .form-control {
        color: $default-color;
        border: none !important;
        height: 45px;
        border-radius: 10px 0px 0px 10px;
        box-shadow: none;
        //   @include placeholder(){
        //     color: $default-color;
        //   };
      }
    }
  }

  p {
    display: inline-block;
    margin: 0;
    line-height: 1.8em;
    font-size: 1em;
    font-weight: 400;
  }

  &.navbar-absolute {
    position: absolute;
    width: 100%;
    z-index: 1029;
    padding: 18px 20px;
    background-color: #1e1e1e;
    padding-top: 15px !important;
  }

  .documentation & {
    &.fixed-top {
      left: 0;
      width: initial;
    }
  }

  .navbar-wrapper {
    display: inline-flex;
    align-items: center;

    .navbar-minimize {
      padding-right: 10px;

      .btn {
        margin: 0;
      }
    }

    .navbar-toggle {
      .navbar-toggler {
        padding-left: 0;
      }

      &:hover {
        & .navbar-toggler-bar.bar2 {
          width: 22px;
        }
      }
    }
  }

  .navbar-nav {
    &.navbar-logo {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 49px;
      top: -4px;
    }

    .nav-link.btn {
      padding: $padding-btn-vertical $padding-btn-horizontal;

      &.btn-lg {
        padding: $padding-large-vertical $padding-large-horizontal;
      }

      &.btn-sm {
        padding: $padding-small-vertical $padding-small-horizontal;
      }
    }

    .nav-link {
      text-transform: uppercase;
      font-size: $font-size-mini;
      padding: $padding-base-vertical $padding-base-horizontal;
      line-height: $line-height-nav-link;
      margin-right: 3px;

      i.fa + p,
      i.nc-icon + p {
        margin-left: 3px;
      }

      i.fa,
      i.nc-icon {
        font-size: 18px;
        position: relative;
        top: 3px;
        text-align: center;
        width: 21px;
      }

      i.nc-icon {
        top: 4px;
        font-size: 16px;
      }

      &.profile-photo {
        .profile-photo-small {
          width: 27px;
          height: 27px;
        }
      }

      &.disabled {
        opacity: 0.5;
        color: $white-color;
      }
    }

    .nav-item.active .nav-link:not(.btn),
    .nav-item .nav-link:not(.btn):focus,
    .nav-item .nav-link:not(.btn):hover,
    .nav-item .nav-link:not(.btn):active {
      border-radius: $border-radius-small;
      color: $default-color;
    }
  }

  .logo-container {
    width: 27px;
    height: 27px;
    overflow: hidden;
    margin: 0 auto;
    border-radius: 50%;
    border: 1px solid transparent;
  }

  .navbar-brand {
    text-transform: capitalize;
    font-size: $font-size-large-navbar;
    padding-top: $padding-base-vertical;
    padding-bottom: $padding-base-vertical;
    line-height: $line-height-nav-link;
  }

  .navbar-toggler {
    width: 37px;
    height: 27px;
    vertical-align: middle;
    outline: 0;
    cursor: pointer;

    & .navbar-toggler-bar.navbar-kebab {
      width: 3px;
      height: 3px;
      border-radius: 50%;
      margin: 0 auto;
    }
  }

  .button-dropdown {
    .navbar-toggler-bar:nth-child(2) {
      width: 17px;
    }
  }

  &.navbar-transparent {
    background: #1e1e1e !important;
    border-bottom: 1px solid #292929;
    padding-top: 15px !important;
    //   border-bottom: 1px solid #ddd;

    a:not(.dropdown-item):not(.btn) {
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 60px;
      color: #ffffff;

      &.disabled {
        opacity: 0.5;
        color: $default-color;
      }
    }

    .button-bar {
      background: $default-color;
    }

    .nav-item .nav-link:not(.btn) {
      color: $default-color;
    }

    .nav-item.active .nav-link:not(.btn),
    .nav-item .nav-link:not(.btn):focus,
    .nav-item .nav-link:not(.btn):hover,
    .nav-item .nav-link:not(.btn):focus:hover,
    .nav-item .nav-link:not(.btn):active {
      color: $primary-color;
    }
  }

  &.bg-white {
    a:not(.dropdown-item):not(.btn) {
      color: $default-color;

      &.disabled {
        opacity: 0.5;
        color: $default-color;
      }
    }

    .button-bar {
      background: $default-color;
    }

    .nav-item.active .nav-link:not(.btn),
    .nav-item .nav-link:not(.btn):focus,
    .nav-item .nav-link:not(.btn):hover,
    .nav-item .nav-link:not(.btn):active {
      color: $info-color;
    }

    .logo-container {
      border: 1px solid $default-color;
    }
  }

  .navbar-collapse {
    .nav-item {
      a {
        font-size: $font-size-base;
      }
    }
  }
}

.bg-default {
  background-color: $default-color !important;
}

.bg-primary {
  background-color: $primary-color !important;
}

.bg-info {
  background-color: $info-color !important;
}

.bg-success {
  background-color: $success-color !important;
}

.bg-danger {
  background-color: $danger-color !important;
}

.bg-warning {
  background-color: $warning-color !important;
}

.bg-white {
  background-color: $white-color !important;
}

.main-div-nav-head {
  .profile-image {
    margin-left: 24px;
  }

  .drop-seller {
    width: 44px;
    height: 44px;

    .drop-down-menu {
      background: #242545 !important;
      border: 1px solid #2c2d49 !important;
      border-radius: 8px !important;
      left: -15px !important;
      width: 323px !important;
      left: unset !important;
      right: -15px !important;
      top: 130% !important;

      padding: 21px;

      .main-outer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .inner-left {
          h5 {
            font-family: "Nunito";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            color: #808191;
          }
        }

        .inner-right {
          h4 {
            font-family: "Nunito";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #3568c1;
          }
        }
      }

      .main-outers {
        display: flex;
        flex-direction: start;
        align-items: center;

        .inner-lefts {
          img {
            max-width: unset !important;
            margin-right: 15px;
          }
        }

        .inner-rights {
          h4 {
            font-family: "Nunito";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #222222;
            padding-bottom: 6px;
          }

          p {
            font-family: "Nunito" !important;
            font-style: normal;
            font-weight: normal;
            font-size: 14px !important;
            line-height: 19px;
            color: #94959b;
            padding-bottom: 5px;
          }

          h6 {
            font-family: "Nunito";
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 140%;
            letter-spacing: 0.004em;
            color: #94959b;

            img {
              padding-right: 5px;
            }
          }
        }
      }

      .dropdown-item {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff !important;

        &:hover {
          color: #1fa5ff !important;
        }
      }
    }

    .dropdown-menu.drop-mainn {
      background: #242545 !important;
      border: 1px solid #2c2d49 !important;
      border-radius: 8px !important;
      left: -100px !important;
      // right: 98px !important;
      top: 110% !important;
      margin-right: 100px;
      width: 100%;
      padding: 10px 0px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff !important;

      &:hover {
        color: #1fa5ff !important;
      }

      .main-outer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .inner-left {
          h5 {
            font-family: "Nunito";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            color: #808191;
          }
        }

        .inner-right {
          h4 {
            font-family: "Nunito";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #3568c1;
          }
        }
      }

      .main-outers {
        display: flex;
        flex-direction: start;
        align-items: center;

        .inner-lefts {
          img {
            max-width: unset !important;
            margin-right: 15px;
          }
        }

        .inner-rights {
          h4 {
            font-family: "Nunito";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #222222;
            padding-bottom: 6px;
          }

          p {
            font-family: "Nunito" !important;
            font-style: normal;
            font-weight: normal;
            font-size: 14px !important;
            line-height: 19px;
            color: #94959b;
            padding-bottom: 5px;
          }

          h6 {
            font-family: "Nunito";
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 140%;
            letter-spacing: 0.004em;
            color: #94959b;

            img {
              padding-right: 5px;
            }
          }
        }
      }

      .dropdown-item {
        color: #353945;
        font-size: 14px;

        &:hover {
          background-color: #1fa5ff;
          color: #fafafb;
          font-weight: 500;
        }
      }
    }

    .button-seller {
      width: 44px;
      height: 44px;
      background: #009de0;
      border-radius: 36px;
      border: none;
      padding: 0px;

      img {
        width: 44px;
        height: 44px;
        border-radius: 36px;
      }
    }

    .arrow-down-p {
      margin-left: 14px;
      cursor: pointer;
    }
  }

  .main-modal-one {
    .daily-profile-modal {
      .daily-profile-modal-inner {
        border-radius: 10px;

        .modal-body-main {
          padding: 50px 40px;

          .main-outter {
            .main-cardssss {
              .form-group {
           

                label {
                  font-family: "Nunito" !important;
                  font-style: normal !important;
                  font-weight: bold !important;
                  font-size: 16px !important;
                  line-height: 22px !important;
                  color: #000000 !important;
                  padding-top: 15px;
                }

                input {
                  background: #fafafb;
                  border: 1px solid #efefef;
                  box-sizing: border-box;
                  border-radius: 5px;
                  height: 55px;

                  &::placeholder {
                    color: #cdcdcd;
                    font-family: "Nunito";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                  }
                }

                textarea {
                  background: #fafafb;
                  border: 1px solid #efefef;
                  box-sizing: border-box;
                  border-radius: 5px;

                  &::placeholder {
                    color: #cdcdcd;
                    font-family: "Nunito";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                  }
                }
              }

              .button-modal-daily {
                display: flex;
                justify-content: space-between;

                .button-main-daily {
                  margin-top: 30px;
                  outline: none;
                  box-shadow: none;
                  background: linear-gradient(180deg, #133572 0%, #3568c1 100%);
                  border-radius: 10px;
                  width: 100%;
                  height: 50px;
                  border: 1px solid #133572;
                  font-family: "Nunito";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 19px;
                  margin-right: 10px;
                  margin-left: 10px;
                  color: #ffffff;
                  transition: 0.4s ease-in-out;

                  &:hover {
                    background: transparent;
                    border: 1px solid #3568c1;
                    color: #3568c1;
                  }
                }

                .button-main-dailys {
                  margin-top: 30px;
                  outline: none;
                  box-shadow: none;
                  background: transparent;
                  border-radius: 10px;
                  width: 100%;
                  height: 50px;
                  border: 1px solid #133572;
                  font-family: "Nunito";
                  font-style: normal;
                  font-weight: 600;
                  margin-right: 10px;
                  margin-left: 10px;
                  font-size: 14px;
                  line-height: 19px;
                  color: #133572;
                  transition: 0.4s ease-in-out;

                  &:hover {
                    background: linear-gradient(
                      180deg,
                      #133572 0%,
                      #3568c1 100%
                    );
                    border: 1px solid #3568c1;
                    color: #ffffff;
                  }
                }
              }

              p {
                font-family: "Nunito";
                font-style: normal;
                font-weight: 600;
                font-size: 14px !important;
                line-height: 19px;
                color: #818094 !important;
                padding-bottom: 6px;
              }

              h4 {
                font-family: "Nunito";
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 27px;
                color: #030229;
              }

              .flux-b {
                padding-bottom: 30px;

                h3 {
                  font-family: "Nunito";
                  font-style: normal;
                  font-weight: bold !important;
                  font-size: 30px !important;
                  line-height: 28px;
                  color: #222222;
                }
              }

              .flux-r {
                padding-left: 39px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .navbar .inner-head .nav-btn-log-out {
    margin-left: 10px;
  }

  .navbar .main-head .main-search-input {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .navbar .navbarsetting .main-head .main-search-input {
    width: 100%;
  }

  .navbar .navbarsetting {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
  }

  .navbar .main-head .main-search-input {
    margin-top: 5px;
  }

  .navbar .inner-head {
    margin-top: 12px;
  }

  .navbar .inner-head .nav-btn-log-out {
    margin-left: 0px;
  }
}

@media (max-width: 600px) {
  .main-div-nav-head {
    .modal-body-main {
      padding: 30px 14px !important;

      .main-cardssss .button-modal-daily .button-main-dailys {
        margin-top: 10px !important;
      }

      .main-cardssss .button-modal-daily .button-main-daily {
        margin-top: 10px !important;
      }
    }
  }
}

@media (max-width: 360px) {
  .main-div-nav-head {
    .modal-body-main {
      padding: 30px 3px !important;

      .flux-b {
        padding-bottom: 18px !important;
      }
    }
  }

  .main-div-nav-head
    .main-modal-one
    .daily-profile-modal
    .daily-profile-modal-inner
    .modal-body-main
    .main-outter
    .main-cardssss
    .flux-b
    h3 {
    font-size: 24px !important;
  }
}

.main-div-nav-head .drop-seller .dropdown-menu.drop-mainn .dropdown-item {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.title-drop {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 120%;
  color: #1fa5ff;
  padding: 0 1.5rem;
}

.inner-label {
  font-style: normal;
  font-weight: 500;
  font-size: 11px !important;
  line-height: 16px;
  color: #7a7b96;
  padding: 0 1.5rem;
  margin-bottom: 20px !important;
}

.dgvsvuvtyxts {
  position: absolute;
  right: 44px;
  top: 7px;
  z-index: 1050;
  .notifications {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 10px;
    background: #1e1e1e;
    border: 1px solid #292929;
    border-radius: 40px;
    width: 44px;
    height: 44px;
    margin-right: 15px;

    .notiimg {
      width: 24px;
      height: 24px;
    }

    .numimg {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 0px;
      right: 0px;
      background: #009de0;
      border: 1px solid #1e1e1e;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      color: #ffffff;
    }
  }

  .noti-inner {
    transform: translate(-89%, 20px) !important;
    margin-top: 20px;
    background: #151515;
    border: 1px solid #353535;
    box-shadow: 9px 10px 30px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 382px;
    max-height: 554px;

    .upper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid #292929;

      .upper-head {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 100%;
        color: #ffffff;
      }

      .upper-para {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        text-align: center;
        color: #009de0;
      }
    }

    .scrollss {
      overflow-y: scroll;
      width: 382px;
      max-height: 479px;

      /* width */
      &::-webkit-scrollbar {
        width: 8px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #232323;
        border-radius: 20px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #009de0;
        border-radius: 20px;
      }

      .lower {
        display: flex;
        align-items: center;
        padding: 20px;
        border-bottom: 1px solid #292929;

        &:hover {
          background: #1e1e1e;
          cursor: pointer;
        }

        .lower-outer {
          width: 42px;
          height: 42px;
          background: #009de0;
          border: 1px solid #292929;
          border-radius: 40px;
          display: flex;
          justify-content: center;
          align-items: center;

          .lower-img {
            width: 24px;
            height: 24px;
          }
        }
      }

      .lower-inner {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-left: 8px;

        .lower-head {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          color: #ffffff;
          margin-bottom: 8px;
        }

        .btnf {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .lower-btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 4px 5px;
            background: #e15151;
            border-radius: 30px;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 100%;
            color: #ffffff;
            border: none;
          }

          .lower-para {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 100%;
            color: #767676;
            margin-left: 8px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .noti {
    display: none !important;
  }
  .dgvsvuvtyxts {
    display: block !important;
  }
}
@media (max-width: 600px) {
  .dgvsvuvtyxts .noti-inner {
    transform: translate(-75%, 20px) !important;
    width: 337px;
  }
  .dgvsvuvtyxts .noti-inner .scrollss {
    width: 334px;
  }
}

@media (max-width: 370px) {
  .dgvsvuvtyxts .noti-inner .scrollss {
    width: 303px;
  }
  .dgvsvuvtyxts .noti-inner {
    width: 305px;
    transform: translate(-68%, 36px) !important;
  }
}
