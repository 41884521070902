.category {
  .parent-heading {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 100%;
      color: #ffffff;
      margin-bottom: 25px;
    }

    .right {
      display: flex;
      align-items: center;
    }

    .dropdown {
      margin-right: 10px;

      button {
        background: #151515;
        border: 1px solid #292929;
        border-radius: 8px;
        padding: 8px 25px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #ffffff;

        &:focus {
          border: 1px solid #009de0;
        }
      }

      .dropdown-menu {
        background: #151515;
        border: 1px solid #292929;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        padding: 20px;

        .input-field {
          label {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #ffffff;
          }

          .form-check-input {
            position: static !important;
            margin-top: 4px;
            margin-right: 8px;
            background-color: #151515;
            border: 2px solid #414141;
            border-radius: 4px;

            &:checked {
              background-color: #009de0;
              border: 2px solid #009de0;
              border-radius: 4px;
            }

            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  .table-responsive {
    background: #1e1e1e;
    border: 1px solid #292929;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    table {
      // .cat-img {
      //   width: 100px;
      // }

      .cat__image {
        width: 45px;
        height: 45px;
        border-radius: 100%;
        border: 2px solid #292929;
        padding: 3px;
      }

      thead {
        background: #212121;
        border-bottom: 1px solid #292929;
      }

      th {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
        color: #767676 !important;
      }

      td {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff !important;
        border-top: 1px solid #292929 !important;
      }

      .id {
        color: #767676 !important;
      }
    }

    .btn-selling {
      background: #00ae11;
      border-radius: 30px;
      padding: 5px 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      color: #ffffff;
    }
  }

  // switch input scss here .......
  .tgl {
    display: none;

    // add default box-sizing for this scope
    &,
    &:after,
    &:before,
    & *,
    & *:after,
    & *:before,
    &+.tgl-btn {
      box-sizing: border-box;

      &::selection {
        background: none;
      }
    }

    +.tgl-btn {
      outline: 0;
      display: block;
      width: 37px;
      height: 20px;
      position: relative;
      cursor: pointer;
      user-select: none;

      &:after,
      &:before {
        position: relative;
        display: block;
        content: "";
        width: 50%;
        height: 100%;
      }

      margin-top: 17px;

      &:after {
        left: 0;
      }

      &:before {
        display: none;
      }
    }

    &:checked+.tgl-btn:after {
      left: 50%;
      background: #fbfbfb;
    }
  }

  // themes

  .tgl-ios {
    +.tgl-btn {
      background: #1e1e1e;
      border: 1px solid #5c5c5c;
      border-radius: 2em;
      padding: 2px;
      transition: all 0.4s ease;

      //   border: 1px solid #e8eae9;
      &:after {
        border-radius: 2em;
        background: #5c5c5c;
        transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
          padding 0.3s ease, margin 0.3s ease;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
      }

      &:hover:after {
        will-change: padding;
      }

      &:active {
        box-shadow: inset 0 0 0 2em #e8eae9;

        &:after {
          padding-right: 0.8em;
        }
      }
    }

    &:checked+.tgl-btn {
      background: #00ae11;

      &:active {
        box-shadow: none;

        &:after {
          margin-left: -0.8em;
        }
      }
    }
  }

  .stockout {
    background: #e15151 !important;
  }

  .sub-items {
    padding-left: 50px !important;
  }

  .sub-items-child {
    padding-left: 90px !important;
  }

  .addcategory-btn {
    background: #009de0;
    border-radius: 8px;
    padding: 8px 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #ffffff;
    border: 1px solid #009de0;
  }
}

// Add Category sidebar scss here..................

.addcategory {
  padding-top: 30px;

  .offcanvas-title {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 100%;
    color: #ffffff;
    margin: 0;
  }

  .offcanvas-body {
    .main-form {
      .bannerss {
        h4 {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 170%;
          display: flex;
          align-items: center;
          color: #009de0;
          margin-bottom: 8px;
        }

        .list-inline-item {
          text-align: center;
        }

        .item-upload {
          background: #1e1e1e;
          border: 1px dashed #313131 !important;
          border-radius: 8px;
          padding: 35px;
          cursor: pointer;

          h4 {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: #ffffff;
            display: block;
            margin-top: 8px;
            margin-bottom: 5px;
          }

          p {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 170%;
            /* or 20px */
            display: flex;
            align-items: center;
            color: #6d6d6d;
          }
        }

        .img-content {
          margin-top: 20px;
        }
      }

      .head {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: #009de0;
        margin-bottom: 8px;
        margin-top: 20px;
      }

      span {
        color: red;
      }

      .des-input {
        background: #1e1e1e;
        border: 1px solid #313131;
        border-radius: 8px;
        padding: 15px 15px 68px 15px;
        width: 100%;
        color: white;

        &::placeholder {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 120%;
          color: #525252;
        }
      }

      input {
        background: #1e1e1e;
        border: 1px solid #313131;
        border-radius: 8px;
        padding: 15px;
        width: 100%;
        color: white;

        &::placeholder {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 120%;
          color: #525252;
        }
      }

      .dropdown {
        .btn {
          background: #1e1e1e;
          border: 1px solid #313131;
          border-radius: 8px;
          width: 100%;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          color: #525252;
          padding: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:focus {
            box-shadow: none;
          }
        }

        .dropdown-menu {
          width: 100%;
          background: #1e1e1e;
          border: 1px solid #313131;
          border-radius: 8px;
          color: white;
          margin-top: 10px;
        }
      }

      .box-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 17px 12px;
          width: 100%;
          background: #313131;
          border-radius: 8px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          color: #ffffff;
          border: none;

          &:hover {
            background: #009de0;
          }
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .category .parent-heading {
    flex-direction: column;
    margin-bottom: 10px;
  }
}

.dropdown-menu a {
  color: #fafafa !important;
}

.ytxyrtdrdyrdyrdytstsy {
  display: flex;
}

.ytxyrtdrdyrdyrdytstsy .backbutton {
  max-width: 300px;
  width: 100%;
}

.ytxyrtdrdyrdyrdytstsy .backbutton .form-check-input[type="checkbox"] {
  position: static !important;
  margin: 4px !important;
}