.banktransfer {
    .customerdetailheading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
        width: 100% !important;

        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 100%;
            color: #FFFFFF;
        }

        .backbutton {
            display: flex;
            align-items: center;

            .btnsdrop {
                .dropdown-menu {
                    background: #1E1E1E !important;
                    color: #FFFFFF;
                }

                button {
                    background: #151515;
                    border: 1px solid #292929;
                    border-radius: 8px;
                    padding: 8px 25px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 100%;
                    color: #FFFFFF;
                    margin-right: 15px;

                    img {
                        margin-right: 7px;
                    }
                }
            }

            .createbtn {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                color: #FFFFFF;
                background: #009DE0;
                border-radius: 8px;
                padding: 8px 10px;
                border: none;
            }
        }
    }

    .table-customer {

        background: #1E1E1E;
        border: 1px solid #292929;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        overflow-x: scroll;

        th {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            // line-height: 100%;
            text-transform: uppercase;
            color: #767676 !important;
            padding: 20px;
            border-bottom: unset !important;
        }
    }

    tbody {
        tr td {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            // line-height: 100%;
            color: #FFFFFF !important;
            border-top: 1px solid #292929 !important;
            padding: 20px 20px;
        }
    }
    .images {
        img {
            margin-right: 20px;
        }
    }
    .detailbutton {

        span {
            background: #009DE0;
            border-radius: 30px;
            padding: 5px 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #FFFFFF;
            border: none;
        }

       
    }
    // switch input scss here .......
  .tgl {
    display: none;

    // add default box-sizing for this scope
    &,
    &:after,
    &:before,
    & *,
    & *:after,
    & *:before,
    &+.tgl-btn {
      box-sizing: border-box;

      &::selection {
        background: none;
      }
    }

    +.tgl-btn {
      outline: 0;
      display: block;
      width: 37px;
      height: 20px;
      position: relative;
      cursor: pointer;
      user-select: none;

      &:after,
      &:before {
        position: relative;
        display: block;
        content: "";
        width: 50%;
        height: 100%;
      }

      margin-top: 17px;

      &:after {
        left: 0;
      }

      &:before {
        display: none;
      }
    }

    &:checked+.tgl-btn:after {
      left: 50%;
      background: #fbfbfb;
    }
  }

  // themes

  .tgl-ios {
    +.tgl-btn {
      background: #1E1E1E;
      border: 1px solid #5C5C5C;
      border-radius: 2em;
      padding: 2px;
      transition: all .4s ease;

      //   border: 1px solid #e8eae9;
      &:after {
        border-radius: 2em;
        background: #5C5C5C;
        transition:
          left .3s cubic-bezier(0.175, 0.885, 0.320, 1.275),
          padding .3s ease, margin .3s ease;
        box-shadow:
          0 0 0 1px rgba(0, 0, 0, .1),
          0 4px 0 rgba(0, 0, 0, .08);
      }

      &:hover:after {
        will-change: padding;
      }

      &:active {
        box-shadow: inset 0 0 0 2em #e8eae9;

        &:after {
          padding-right: .8em;
        }
      }
    }

    &:checked+.tgl-btn {
      background: #00AE11;

      &:active {
        box-shadow: none;

        &:after {
          margin-left: -.8em;

        }
      }
    }
  }
      .unpublished{
        background: #E15151 !important;
      }
      
}


//banktransfersidebar..........//

.sidebar-banktransferproduct {
  padding-top: 30px;
  .offcanvas-title {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 100%;
    color: #FFFFFF;
    margin: 0;
  }

  .offcanvas-body {
   
       
        h4 {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 170%;
          display: flex;
          align-items: center;
          color: #009DE0;
          margin-bottom: 8px;
        }

        .list-inline-item {
          text-align: center;
        }

        .item-upload {
          background: #1E1E1E;
          border: 1px dashed #313131 !important;
          border-radius: 8px;
          padding: 35px;
          cursor: pointer;

          h4 {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: #FFFFFF;
            display: block;
            margin-top: 8px;
            margin-bottom: 5px;
          }

          p {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 170%;
            /* or 20px */
            display: flex;
            align-items: center;
            color: #6D6D6D;
          }
        }

        .img-content {
          margin-top: 20px;
        }
      }

     p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: #009DE0;
        margin-bottom: 8px;
        margin-top: 20px;
      }

      input {
        background: #1E1E1E;
        border: 1px solid #313131;
        border-radius: 8px;
        padding: 15px;
        width: 100%;
        color: white;

        &::placeholder {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 120%;
          color: #525252;
        }
      }

      .dropdown {
        .btn {
          background: #1E1E1E;
          border: 1px solid #313131;
          border-radius: 8px;
          width: 100%;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          color: #525252;
          padding: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:focus {
            box-shadow: none;
          }
        }
        .dropdown-menu {
          width: 100%;
          background: #1E1E1E;
          border: 1px solid #313131;
          border-radius: 8px;
          color: white;
          margin-top: 10px;
        }
      }

      .box-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px;
          width: 100%;
          background: #313131;
          border-radius: 8px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          color: #FFFFFF;
          border: none;
          &:hover{
            background: #009DE0;
          }
        }
      }
      .buttonss{
        display: flex;
        button{
          background: #313131;
          border-radius: 8px;
          padding: 12px;
          width: 100%;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          color: #FFFFFF;
          white-space: nowrap;
          margin-top: 25px;
          margin-bottom: 10px;
          border: none;
          &:hover{
            background: #009DE0;
          }
        }    
      }
     
    }
    @media (max-width:420px){
      .banktransfer .customerdetailheading {
        flex-direction: column;
        margin-bottom: 10px;
        justify-content: flex-start;
        align-items: flex-start;
      }
      .banktransfer .customerdetailheading h3 {
        margin-bottom: 10px;
      }
    }

    .settabs{
        background: #1E1E1E;
        border: 1px solid #313131;
        border-radius: 8px;
        padding: 3px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .nav-item{
            flex: 1;
        }
        .nav-link{
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #FFFFFF;
            border: none;
            background-color: transparent;
            text-align: center;
            width: 100%;
            padding: 12px;
        }
         .nav-link.active,  .nav-item.show .nav-link{
            background: #313131;
            border-radius: 8px;
            border: none;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #FFFFFF;
        }

      }