.Discount {
    .parent-heading {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        h4 {
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 100%;
            color: #ffffff;
            margin-bottom: 25px;
        }

        .itemsssss {
            .search-field {
                input {
                    padding-right: 112px;
                }

                .discountCSV-dropdown {
                    border-left: 1px solid #292929;
                    padding-left: 10px;
                }

            }
        }




        .drop__input-field {
            .input-field {
                display: flex;
                // align-items: center;
            }
        }

        .dropdown {
            margin-right: 10px;

            .filter-dropdown-button {
                width: 190px;
            }

            button {
                background: #151515;
                border: 1px solid #009de0;
                border-radius: 8px;
                padding: 8px 25px;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                color: #ffffff;
            }

            .dropdown-menu {
                background: #151515;
                border: 1px solid #292929;
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
                border-radius: 8px;
                padding: 20px;



                .input-field {
                    label {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: #ffffff;
                    }

                    .form-check-input {
                        position: static !important;
                        margin-top: 4px;
                        margin-right: 8px;
                        background-color: #151515;
                        border: 2px solid #414141;
                        border-radius: 4px;

                        &:checked {
                            background-color: #009de0;
                            border: 2px solid #009de0;
                            border-radius: 4px;
                        }

                        &:focus {
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }

    .customerdetailheading {
        // display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;

        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 100%;
            color: #ffffff;
        }

        .backbutton {
            display: flex;
            align-items: center;

            .btnsdrop {
                .dropdown-menu {
                    background: #1e1e1e !important;
                    color: #ffffff;
                }

                button {
                    background: #151515;
                    border: 1px solid #292929;
                    border-radius: 8px;
                    padding: 8px 25px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 100%;
                    color: #ffffff;
                    margin-right: 15px;

                    img {
                        margin-right: 7px;
                    }
                }
            }

            .createbtn {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                color: #ffffff;
                background: #009de0;
                border-radius: 8px;
                padding: 8px 10px;
                border: none;
            }
        }
    }

    .table-customer {
        background: #1e1e1e;
        border: 1px solid #292929;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        //   overflow-x: scroll;

        th {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            // line-height: 100%;
            text-transform: uppercase;
            color: #767676 !important;
            padding: 20px;
            border-bottom: unset !important;
        }
    }

    tbody {
        tr td {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            // line-height: 100%;
            color: #ffffff !important;
            border-top: 1px solid #292929 !important;
            padding: 20px 20px;
        }
    }

    .images {
        img {
            margin-right: 20px;
            cursor: pointer;
        }

        button {
            // padding: 8px 15px;
            // width: 72px;
            // height: 28px;
            // background: #1E1E1E;
            // border: 1px solid #009DE0;
            // border-radius: 30px;

            padding: 5px 15px;
            background: #1E1E1E;
            border: 1px solid #009DE0;
            border-radius: 30px;
            color: white;
            margin-right: 12px;
        }
    }

    .detailbutton {
        span {
            background: #009de0;
            border-radius: 30px;
            padding: 5px 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #ffffff;
            border: none;
        }
    }

    // switch input scss here .......
    .tgl {
        display: none;

        // add default box-sizing for this scope
        &,
        &:after,
        &:before,
        & *,
        & *:after,
        & *:before,
        &+.tgl-btn {
            box-sizing: border-box;

            &::selection {
                background: none;
            }
        }

        +.tgl-btn {
            outline: 0;
            display: block;
            width: 37px;
            height: 20px;
            position: relative;
            cursor: pointer;
            user-select: none;

            &:after,
            &:before {
                position: relative;
                display: block;
                content: "";
                width: 50%;
                height: 100%;
            }

            margin-top: 17px;

            &:after {
                left: 0;
            }

            &:before {
                display: none;
            }
        }

        &:checked+.tgl-btn:after {
            left: 50%;
            background: #fbfbfb;
        }
    }

    // themes

    .tgl-ios {
        +.tgl-btn {
            background: #1e1e1e;
            border: 1px solid #5c5c5c;
            border-radius: 2em;
            padding: 2px;
            transition: all 0.4s ease;

            //   border: 1px solid #e8eae9;
            &:after {
                border-radius: 2em;
                background: #5c5c5c;
                transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
                    padding 0.3s ease, margin 0.3s ease;
                box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
            }

            &:hover:after {
                will-change: padding;
            }

            &:active {
                box-shadow: inset 0 0 0 2em #e8eae9;

                &:after {
                    padding-right: 0.8em;
                }
            }
        }

        &:checked+.tgl-btn {
            background: #00ae11;

            &:active {
                box-shadow: none;

                &:after {
                    margin-left: -0.8em;
                }
            }
        }
    }

    .unpublished {
        background: #e15151 !important;
    }
}

//promosidebar..........//
.UploadCSVModal-section {
    position: relative;
}

.sidebar-promoproduct {
    padding-top: 30px;

    .offcanvas-title {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 100%;
        color: #ffffff;
        margin: 0;
    }

    .offcanvas-body {
        overflow-y: auto;
        position: relative;

        h4 {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: #009de0;
            margin-bottom: 8px;
        }

        .list-inline-item {
            text-align: center;
        }

        .item-upload {
            background: #1e1e1e;
            border: 1px dashed #313131 !important;
            border-radius: 8px;
            padding: 35px;
            cursor: pointer;

            h4 {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 170%;
                display: flex;
                align-items: center;
                color: #ffffff;
                display: block;
                margin-top: 8px;
                margin-bottom: 5px;
            }

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 170%;
                /* or 20px */
                display: flex;
                align-items: center;
                color: #6d6d6d;
            }
        }

        .img-content {
            margin-top: 20px;
        }
    }

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: #009de0;
        margin-bottom: 8px;
        margin-top: 20px;
    }

    input {
        background: #1e1e1e;
        border: 1px solid #313131;
        border-radius: 8px;
        padding: 15px;
        width: 100%;
        color: white;

        &::placeholder {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 120%;
            color: #525252;
        }
    }

    .list-body-category {
        margin-top: 8px;

        .table-category {
            border-radius: 8px;
            border: 1px solid #292929;


            .thead-categories {
                background-color: #212121;
                width: 100%;
                border-bottom: 1px solid #292929;


                th {
                    color: #767676 !important;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 14px;
                }

                .th-id {
                    color: #767676 !important;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 14px;
                    padding-left: 20px;
                }
            }

            .tbody-categories {

                border-top: none !important;
                border-bottom: 1px solid #292929;

                .item-id {
                    color: #767676 !important;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 14px;
                    padding: 20px;

                }

                .item-name {
                    color: #ffffff !important;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 14px;

                    .image-categories {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }

                .delete-img {
                    line-height: 0px;
                }
            }
        }
    }

    .dropdown {
        .dropdownbtn {
            .dropdown {
                button {
                    background: #1e1e1e;
                    border: 1px solid #313131;
                    border-radius: 8px;
                    margin-top: 8px;
                    padding: 15px;
                    width: 100%;
                    text-align: start;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .button.btn.btn-secondary.dropdown-toggle.button {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border: 1.5px solid #009de0;
                }
            }

            .dropup .dropdown-toggle:after,
            .dropdown .dropdown-toggle:after {
                display: none;
            }

            .dropdown-menu.show {
                background: #1e1e1e;
                border: 1px solid #292929;
                border-radius: 8px;
                width: 100%;
                padding: 15px 10px;

                .list-body {
                    overflow: scroll;
                    max-height: 285px;
                }

                .list-body-prod {
                    overflow: scroll;
                    max-height: 190px;
                }

                .main-parent {
                    position: relative;

                    input {
                        background: #1e1e1e;
                        border: 1px solid #313131;
                        border-radius: 8px;
                        width: 100%;
                        margin-top: 8px;
                        padding: 15px;
                        margin-bottom: 19px;
                        padding-left: 45px;
                        color: #ffffff;
                    }

                    img {
                        position: absolute;
                        top: 23px;
                        left: 10px;
                    }
                }

                .form-check-input {
                    position: static !important;
                    margin-top: 4px;
                    margin-right: 8px;
                    background-color: #151515;
                    border: 2px solid #414141;
                    border-radius: 4px;

                    &:checked {
                        background-color: #009de0;
                        border: 2px solid #009de0;
                        border-radius: 4px;
                    }

                    &:focus {
                        box-shadow: none;
                    }
                }

                .form-group {
                    display: block;
                    margin-bottom: 12px;
                    border-bottom: 1px solid #292929;

                    &:last-child {
                        border-bottom: none;
                    }
                }

                .form-group input {
                    padding: 0;
                    height: initial;
                    width: initial;
                    margin-bottom: 0;
                    display: none;
                    cursor: pointer;
                }

                .form-group label {
                    position: relative;
                    cursor: pointer;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 170%;
                    display: flex;
                    align-items: center;
                    color: #ffffff;
                }

                .form-group label:before {
                    content: "";
                    -webkit-appearance: none;
                    padding: 10px;
                    display: inline-block;
                    position: relative;
                    vertical-align: middle;
                    cursor: pointer;
                    margin-right: 15px;

                    background-color: #1e1e1e;
                    border: 1px solid #313131;
                    border-radius: 5px;
                }

                .form-group input:checked+label:before {
                    background: #009de0;
                    // border-radius: 15px;
                }

                .form-group input:checked+label:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 12px;
                    left: 9px;
                    width: 6px;
                    height: 14px;
                    border: solid #fff;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                }

                .AddSelected {
                    margin-top: 7px;

                    button {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 12px 14px;
                        background: #009de0;
                        border-radius: 8px;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 100%;
                        color: #ffffff;
                        width: 100%;
                    }
                }
            }
        }

        .btn {
            background: #1e1e1e;
            border: 1px solid #313131;
            border-radius: 8px;
            width: 100%;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #525252;
            padding: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:focus {
                box-shadow: none;
            }
        }

        .dropdown-menu {
            width: 100%;
            background: #1e1e1e;
            border: 1px solid #313131;
            border-radius: 8px;
            color: white;
            margin-top: 10px;
        }
    }

    .box-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 12px;
            width: 100%;
            background: #313131;
            border-radius: 8px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #ffffff;
            border: none;

            &:hover {
                background: #009de0;
            }
        }
    }

    .Upload-CSV-Modal-buttons {
        position: absolute;
        width: 96%;
        bottom: 5px;
        left: 15px;
    }

    .buttonss {
        display: flex;


        button {
            background: #313131;
            border-radius: 8px;
            padding: 17px 12px;
            width: 100%;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #ffffff;
            white-space: nowrap;
            margin-top: 25px;
            margin-bottom: 10px;
            border: none;

            &:hover {
                background: #009de0;
            }
        }
    }
}

@media (max-width: 420px) {
    .Discount .customerdetailheading {
        flex-direction: column;
        margin-bottom: 10px;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .Discount .customerdetailheading h3 {
        margin-bottom: 10px;
    }
}

.koi-bhi {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.ytxfxxtyxhtctcty input:checked+label:after {
    content: "";

    top: 5px !important;
    left: 8px !important;
}


.Discount .customerdetailheading .backbutton .createbtn {
    max-width: 157px;
    width: 100%;
}

.Discount .customerdetailheading .backbutton .create-discount {
    max-width: 170px;
    width: 100%;
}

.Discount .customerdetailheading .backbutton .Upload-CSV-btn {
    max-width: 135px;
    width: 100%;
}


.Banner__Management__Img {
    img {
        max-width: 100px;
        height: 50px;
        border-radius: 3px;
        width: 100px;
        object-fit: cover;
    }
}


.itemsssss .discount-search {
    top: 15px;
  
    .input-field {
      input {
        padding: 0px !important;
      }
    }
  }