.setting {
  overflow: hidden;
  .main-heading {
    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 22px !important;
      line-height: 100%;
      color: #ffffff;
      text-align: left;
      margin-bottom: 25px;
    }
  }

  .main-inputfelid {
    background: #1e1e1e;
    border: 1px solid #292929;
    border-radius: 10px;
    padding: 40px 20px;

    .filedinput {
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: #009de0;
        margin-bottom: 8px;
      }

      input {
        background: #1e1e1e;
        border: 1px solid #313131;
        border-radius: 8px;
        padding: 15px;
        width: 100%;
        margin-bottom: 25px;
        color: #ffffff;
        // color: #525252;
      }

      .admin {
        background: #1e1e1e;
        border: 1px solid #313131;
        border-radius: 8px;
        padding: 15px;
        width: 100%;
        margin-bottom: 25px;
        color: #ffffff;

        &::placeholder {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          /* identical to box height, or 14px */

          color: #ffffff;
        }
      }
    }
  }

  .bannerss {
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 170%;
      display: flex;
      align-items: center;
      color: #009de0;
      margin-bottom: 8px;
    }

    .list-inline-item {
      text-align: center;
      .upload-img {
        height: 265px;
      }
    }

    .item-upload {
      background: #1e1e1e;
      border: 1px dashed #313131 !important;
      border-radius: 8px;
      padding: 35px;
      display: flex;
      justify-content: center;
      h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: #ffffff;
        display: block;
        margin-top: 8px;
        margin-bottom: 5px;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 170%;
        /* or 20px */

        display: flex;
        align-items: center;

        color: #6d6d6d;
      }
    }

    .img-content {
      margin-top: 20px;
    }
  }

  .password {
    position: relative;

    a {
      position: absolute;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #009de0;
      right: 16px;
      top: 52px;
    }
  }

  .buttonupload {
    display: flex;
    justify-content: right;

    button {
      padding: 17px 55px;
      background: #009de0;
      border-radius: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #ffffff;
      border: none;
      margin-top: 214px;
      margin-bottom: 56px;
    }
  }
}

.modal-password {
  .modal-title {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 100%;
    color: #ffffff;
    margin-top: 0px;
    margin-left: 10px;
    margin-bottom: 36px;
  }

  .modal-header {
    border-bottom: unset !important;
    padding: 0px;
  }

  .modal-content {
    background: #1e1e1e;
    border: 1px solid #363636;
    border-radius: 10px;

    padding: 20px;
  }

  .modal-body {
    .passeye {
      border: none;
      text-decoration: none;
      background: transparent;
    }
    .del-img {
      img {
        display: block;
        margin: 0 auto;
      }
    }
    .changepassword {
      position: relative;

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: #009de0;
        margin-bottom: 8px;
      }

      input {
        background: #313131;
        border: 1px solid #313131;
        border-radius: 8px;
        width: 100%;
        padding: 15px;
        margin-bottom: 20px;
        color: #ffffff;
      }
    }

    .eye {
      position: absolute;
      top: 50px;
      right: 10px;
    }
  }

  .modal-footer {
    border-top: unset !important;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      background: #313131;
      border-radius: 8px;
      align-items: center;
      padding: 17px 55px;
      border: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #ffffff;
      // margin-right: 15px;
      white-space: nowrap;
      width: 170px;
      &:last-child {
        margin-right: 0;
        padding: 17px 0px;
      }

      &:hover {
        background-color: #009de0;
      }
    }

    .del-footer {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        background: #313131;
        border-radius: 8px;
        align-items: center;
        padding: 17px 55px;
        border: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #ffffff;
        // margin-right: 15px;
        white-space: nowrap;

        &:hover {
          background-color: #e15151;
        }
      }
    }
  }
  .del-heading {
    h5 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      text-align: center;
      color: #ffffff;
      margin-top: 20px;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      margin-top: 15px;
      display: flex;
      align-items: center;
      text-align: center;
      margin-bottom: 35px;
      color: #6d6d6d;
    }
  }
}

@media (max-width: 600px) {
  .modal-password .modal-footer .button-footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-password .modal-footer .button-footer button {
    padding: 17px 20px;
  }
  .modal-password .modal-footer .del-footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-password .modal-footer .del-footer button {
    padding: 17px 20px;
  }
  .setting .main-inputfelid {
    padding: 25px 12px;
  }
  .setting .buttonupload button {
    margin-top: 60px;
  }
  .modal-password .modal-footer button {
    width: 100%;
  }
}
