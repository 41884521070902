section.main-dashboard {
  .Overview_main {
    margin-top: 13px;
    h4.headdingg {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 100%;
      color: #ffffff;
    }
  }
  .carddashbord {
    width: 100%;
    // height: 94px;
    /* left: 265px; */
    /* top: 148px; */
    background: #1e1e1e;
    border-radius: 10px;
    margin-top: 25px;
    // margin-left: -15px;
    // width: 260px;
    .carrdmainflex {
      display: flex;
      // justify-content: center;
      align-items: center;
      padding-top: 22px;
      padding-bottom: 24px;
      padding-left: 25px;
    }
    .cardpic {
      padding-right: 20px;
    }
    p.headdinggfst {
      font-style: normal;
      font-weight: 500;
      font-size: 14px !important;
      line-height: 100%;
      color: #8b8b8b;
      margin-bottom: 10px;
    }
    p.headdinggfstdd {
      font-style: normal;
      font-weight: 600;
      font-size: 22px !important;
      line-height: 100%;
      color: #ffffff;
    }
  }
  .Overview_oder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 34px;
    margin-bottom: 29px;
    h4.ooddeerr {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 100%;
      color: #ffffff;
      white-space: nowrap;
    }
    button.viewall {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #ffffff;
      background: #151515;
      border: 1px solid #009de0;
      border-radius: 5px;
      justify-content: center;
      align-items: center;
      padding: 8px 15px;
      white-space: nowrap;
      box-shadow: 0px 4px 4px #00000040;
    }
  }
  .table-responsive {
    background: #1e1e1e;
    border: 1px solid #292929;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    justify-content: center;

    table.table.bashbord_table {
      // background: #e4c9c9 !important;
      // border: 1px solid #292929 !important;
      // border-radius: 10px !important;
      thead.bashbord_table_head {
        // background: #212121;
        // border-bottom: 1px solid #292929;
      }
      tr.tablerow {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        // line-height: 30px;
        text-transform: uppercase;
        color: #767676;
        background: #212121;
        th {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          // line-height: 100% !important;
          text-transform: uppercase;
          color: #767676 !important;
          // background: #212121;
          border-bottom: 1px solid #292929 !important;
        }
      }
    }
    .table tbody tr td {
      text-align: unset;
      font-style: normal;
      font-weight: unset;
      font-size: unset;
      text-transform: unset;
      // color: #7a7b96 ;
      // line-height: 100% !important;
      white-space: nowrap;
      border-top: none !important;
      // text-align: left !important;
    }
    tr.fstrowtable {
      border-bottom: 1px solid #292929;

      p.fstrownum {
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        // line-height: 100% !important;
        text-transform: uppercase;
        color: #767676;
      }
      p.fstrownumsec {
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        color: #ffffff;
        align-items: start;
        justify-content: center;
        text-align: start;
        display: inline;
        // line-height: 100% !important;
      }
      p.fstrownumthrd {
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        // line-height: 100% !important;
        color: #ffffff;
      }
      p.fstrownumfour {
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        // line-height: 100% !important;
        color: #ffffff;
      }
      p.fstrownumfive {
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        // line-height: 100% !important;
        color: #ffffff;
      }
      span.greenprocesss {
        background: #009de0;
        border-radius: 30px;
        padding: 5px 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        // line-height: 100% !important;
        color: #ffffff;
      }
      a.tble_details {
        background: #009de0;
        border-radius: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        // line-height: 100% !important;
        color: #ffffff;
        padding: 10px 15px;
        white-space: nowrap;
        border: 1px solid transparent;

        &:hover {
          background: white;
          color: #009de0;
          border: 1px solid #ffffff;
        }
      }
      button#dropdownMenuButton {
        background: #1e1e1e;
        border: 1px solid #525252;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        padding: 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        // line-height: 100% !important;
        color: #525252;
        white-space: nowrap;
        width: 110px;
        // margin: 13px;
      }
      .dropdown-menu.menneuee {
        background: #151515;
        border: 1px solid #292929;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        white-space: nowrap;
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 50% !important;
        text-transform: uppercase;
        a.dropdown-item {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #ffffff;
        }
      }
    }
    h4.lastpager {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      // line-height: 100% !important;
      text-transform: uppercase;
      color: #767676;
    }
    ul.pagination {
      border: none;
      background: none;
      .page-link {
        position: relative;
        display: block;
        padding: 0.5rem 0.75rem;
        margin-left: -1px;
        line-height: 1.25;
        // color: #007bff;
        background-color: transparent;
        border: none;
        &:focus {
          box-shadow: none;
        }
      }
      li.page-item.numbersss {
        background: #009de0;
        border-radius: 7px;
        padding: 6px 11px;
        // line-height: 100% !important;

        a.page-link {
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          // line-height: 100%;
          color: #ffffff;
        }
      }
    }
  }

  tfoot {
    .right {
      .page-link {
        background: transparent;
        border: none;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 100%;
        color: #757575;
        &:focus {
          box-shadow: none;
        }

        &.active {
          background: #009de0;
          border-radius: 5px;
          color: #fff;
        }
      }
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 37px;
      text-transform: uppercase;
      color: #767676;
    }

    ul {
      justify-content: flex-end;
    }
  }
  .greenprocesss {
    background: #009de0;
    border-radius: 30px;
    padding: 5px 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 100% !important;
    color: #ffffff;
    width: 86px;
  }
  .greenprocesssgreen {
    background: #00ae11;
    border-radius: 30px;
    padding: 5px 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100% !important;
    color: #ffffff;
  }
  .greenprocesssyellow {
    background: #ffb800;
    border-radius: 30px;
    padding: 5px 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100% !important;
    color: #ffffff;
  }
  .greenprocesssred {
    background: #e95842;
    border-radius: 30px;
    padding: 5px 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100% !important;
    color: #ffffff;
  }
  .table th,
  .table td {
    border-top: none !important;
  }
}
.supportdetails_fst {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -22px;
  h4.heading_support {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 100%;
    color: #ffffff;
    white-space: nowrap;
  }
  button.btn_resolve {
    background: #00ae11;
    border-radius: 8px;
    padding: 8px 10px;
    gap: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    border: 1px solid #00ae11;
    color: #ffffff;
    white-space: nowrap;
    &:hover {
      background: #8edda8;
      color: #036b26;
      border: 1px solid #00ae11;
    }
  }
}

img.avtartablesupdetl {
  /* background: #FFFFFF; */
  border: 2px solid #292929;
  border-radius: 50%;
  width: 108px;
  height: 95px;
  object-fit: cover;
  /* background: #FFD600; */
  /* border-radius: 36px; */
}

.carrdmainflexx {
  display: flex;
  padding: 35px 40px;
  align-items: flex-start;
  align-content: center;

  .cardpichnds {
    padding-left: 40px;
  }
  .centermaindiv {
    display: flex;
    align-items: center;
  }
  p.headdinggfsts {
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 100%;
    color: #767676;
    padding-bottom: 5px;
    white-space: nowrap;
  }
  p.headdinggfstdds {
    font-style: normal;
    // font-weight: 600;
    font-size: 16px !important;
    line-height: 100%;
    color: #ffffff;
    padding-bottom: 25px;
    white-space: nowrap;
  }
}

@media (max-width: 1199px) {
  // .padd-l {
  //     padding-left: 15px !important;
  // }
  .padd-r {
    padding-right: 0px !important;
  }
  .thrdpadd {
    padding-left: 0px !important;
  }
}
@media (max-width: 600px) {
  // .assignorder {
  //     --bs-offcanvas-width: 430px;
  // }
  .main-dashboard .padd {
    padding: 0 !important;
  }
}

@media (max-width: 790px) {
  .main-dashboard .padd {
    // padding: 0 !important;
  }

  section.main-dashboard .carddashbord {
    text-align: center;
  }
  .supportdetails_fst {
    display: grid;
    justify-content: space-between;

    margin-top: -16px;
  }
  .supportdetails_fst h4.heading_support {
    margin-bottom: 15px;
  }
  .carrdmainflexx {
    display: grid;
    padding: 18px 23px;
    justify-content: center;
  }
  .carrdmainflexx .centermaindiv {
    display: grid;
    padding-top: 15px;
    justify-content: center;
  }
  .carrdmainflexx .cardpichnds {
    padding-left: 5px;
    padding-top: 10px;
  }
  .cardpichnd {
    text-align: left;
  }
}

@media (max-width: 330px) {
  section.main-dashboard .Overview_oder {
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: 34px;
    margin-bottom: 29px;
  }
  section.main-dashboard .Overview_oder button.viewall {
    white-space: nowrap;
    margin-top: 16px;
  }
  // .main-dashboard .padd {
  //     padding: 0 !important;
  // }
}
