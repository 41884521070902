.login___form {
    margin-top: 40px;
}

.bottom__line {
    margin-bottom: 40px;
}

.main-login {
    height: 100vh;
    background: #000 !important;

    .inner-logo {
        // img {
        //     width: 150px;
        // }
    }

    .dhvbyudv {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .form-check-input {
        position: absolute;
        margin-top: 9px;
        margin-left: 0;
    }

    .cmn-tile-style {
        // background: #181936;
        // border: 1px solid #2C2D49;
        // box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.05);
        // border-radius: 20px;
        // padding: 30px;
        // margin-top: 30px;
        // width: 100%;
        // width: 500px;
        // margin: 0 auto;
        // margin-top: 150px;
        margin: 0 auto;
        /* margin-top: 150px; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;
        padding: 0px 149px;

        h2 {

            font-style: normal !important;
            font-weight: 600 !important;
            font-size: 28px !important;
            line-height: 100%;
            color: #FFFFFF;
            margin-bottom: 9px;
        }

        .cccc {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #525252;
            margin-top: 5px;
        }

        h6 {
            font-weight: normal;
            color: #636363;
        }

        label {

            padding-top: 0px;
            font-style: normal !important;
            font-weight: 500 !important;
            font-size: 14px !important;
            line-height: 170% !important;
            color: #009DE0 !important;
        }

        input {
            box-sizing: border-box;
            height: 65px;
            border-radius: 14px;
            padding: 18px 15px;
            background: #151515 !important;
            border: 1px solid #313131 !important;
            border-radius: 8px !important;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #FFFFFF;

            &::placeholder {
                color: #525252;
            }

            &:focus {
                box-shadow: none;
            }
        }

        .btn-login {
            background: #009DE0 !important;
            border-radius: 8px !important;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #FFFFFF;
            box-shadow: none;
            padding: 20px;

        }

        .keep-label {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #FFFFFF !important;
        }

        .forgot {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            text-decoration-line: underline !important;
            color: #009DE0;
        }
    }

    .inputErrors {
        color: red;
    }
}

.upper-hh {
    position: relative;
}

.upper-nn {
    position: absolute;
    top: 22px;
    left: 13px;
}

.upper-nnn {
    position: absolute;
    top: 19px;
    left: 13px;
}

.upper-nnnn {
    position: absolute;
    top: 15px;
    right: 13px;
}


////////forgetpassword scss.................////////
.main-forgetpassword {
    padding-top: 50px;

    .maincardforget {
        background: #1E1E1E;
        box-shadow: 0px 3px 50px rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        padding: 50px;

        .imagecard {
            text-align: center;
            margin-bottom: 67px;
        }

        .main-text {

            h3 {
                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 110%;
                margin-bottom: 15px;
                color: #FFFFFF;
            }

            p {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 130%;
                margin-bottom: 40px;
                color: #525252;
            }
        }

        .parainput {
            h3 {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 170%;
                color: #009DE0;
            }

            input {
                background: #1A1A1A;
                border: 1px solid #313131;
                border-radius: 8px;
                padding: 18px 15px;
                width: 100%;
                margin-bottom: 41px;

                &::placeholder {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 100%;
                    color: #525252;
                }
            }
        }

        .send {
            button {
                background: #009DE0;
                border-radius: 8px;
                padding: 19px 45px;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 100%;
                color: #FFFFFF;
                width: 100%;
                border: none;
            }
        }
    }
}

////////////................ checkemail scss........///////
.checkemail {
    padding-top: 50px;

    .checkemailcard {
        background: #1E1E1E;
        box-shadow: 0px 3px 50px rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        padding: 50px;

        .imagecard {
            text-align: center;
            margin-bottom: 20px;
        }

        h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 110%;
            color: #FFFFFF;
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 130%;
            color: #525252;
            margin-top: 15px;
            margin-bottom: 41px;
        }

        .ok {
            button {
                background: #009DE0;
                border-radius: 8px;
                padding: 19px 45px;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 100%;
                color: #FFFFFF;
                width: 100%;
                border: none;
            }
        }
    }
}

/////////..........newpassword scss........///////
.newpassword {
    padding-top: 50px;

    .newpasswordcard {
        background: #1E1E1E;
        box-shadow: 0px 3px 50px rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        padding: 50px;

        .textcontent {
            h2 {
                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 110%;
                color: #FFFFFF;
            }

            p {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 130%;
                color: #A3A3A3;
                margin-top: 15px;
                margin-bottom: 40px;
            }

            .newpasswordinput {
                h3 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 170%;
                    display: flex;
                    align-items: center;
                    color: #009DE0 !important;
                    margin-bottom: 8px;
                }

                .parent {
                    position: relative;

                    input {
                        background: #1A1A1A;
                        border: 1px solid #313131;
                        border-radius: 8px;
                        padding: 18px 15px;
                        width: 100%;
                        margin-bottom: 20px;

                        &::placeholder {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 100%;
                            color: #525252;
                        }
                    }

                    img {
                        position: absolute;
                        top: 20px;
                        right: 20px;
                    }
                }

                .red {
                    position: relative;

                    input {
                        background: #1A1A1A;
                        border: 1px solid #FE0000;
                        border-radius: 8px;
                        padding: 18px 15px;
                        width: 100%;
                        margin-bottom: 20px;

                        &::placeholder {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 100%;
                            color: #525252;
                        }
                    }

                    img {
                        position: absolute;
                        top: 20px;
                        right: 20px;
                    }
                }

                h3 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    color: #FFFFFF;
                }

                .headingsss {
                    padding-right: 20px;
                    padding-bottom: 20px;

                    ul {
                        list-style: disc;

                        ::marker {
                            color: #009DE0;
                            width: 6px;
                            height: 6px;
                        }

                        li {
                            p {
                                font-style: normal;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 21px;
                                color: #A3A3A3;
                                margin-top: 0px;
                                margin-bottom: 0px;
                                padding-bottom: 2px;
                            }
                        }
                    }
                }

                .confirm {
                    button {
                        background: #009DE0;
                        border-radius: 8px;
                        padding: 19px 45px;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 100%;
                        color: #FFFFFF;
                        width: 100%;
                        border: none;
                        margin-top: 41px;
                    }
                }
            }
        }
    }
}

///////////..........success scss ........///////
.success {
    padding-top: 50px;

    .mainsuccesscard {
        background: #1E1E1E;
        box-shadow: 0px 3px 50px rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        padding: 50px;

        .imagecard {
            text-align: center;
        }

        h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 110%;
            text-align: center;
            color: #FFFFFF;
            margin-top: 59px;
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 130%;
            text-align: center;
            color: #A3A3A3;
            margin-top: 15px;
        }

        .login {
            button {
                background: #009DE0;
                border-radius: 8px;
                padding: 19px 45px;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 100%;
                color: #FFFFFF;
                width: 100%;
                border: none;
                margin-top: 41px;
            }
        }
    }
}

@media(max-width:991px) {
    .main-login .maincardforget .main-text h3 {
        font-size: 16px;
    }

    .main-login .maincardforget .main-text p {
        font-size: 11px;
    }

    .checkemail .checkemailcard h3 {
        font-size: 16px;
    }

    .checkemail .checkemailcard p {
        font-size: 11px;
        white-space: nowrap;
    }

    .newpassword .newpasswordcard .textcontent p {
        font-size: 11px;
        white-space: nowrap;
    }

    .newpassword .newpasswordcard .textcontent h2 {
        font-size: 16px;
    }
}