.customer {
  .customertable {
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 100%;
      color: #ffffff;
      margin-bottom: 25px;
    }
  }

  .table-customer {
    background: #1e1e1e;
    border: 1px solid #292929;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow-x: scroll;

    th {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      text-transform: uppercase;
      color: #767676 !important;
      padding: 20px;
      border-bottom: unset !important;
    }
  }

  tbody {
    .idaff {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase;
      color: #767676 !important;
      // line-height: 100%;
    }

    tr td {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      // line-height: 100%;
      color: #ffffff !important;
      border-top: 1px solid #292929 !important;
      padding: 20px 20px;
    }
  }

  .detailbutton {
    button {
      background: #009de0;
      border-radius: 5px;
      padding: 8px 14px;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #ffffff;
      border: none;
      margin-right: 12px;
    }
  }

  .parent-heading {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 100%;
      color: #ffffff;
      margin-bottom: 25px;
    }

    .dropdown {
      margin-right: 10px;

      button {
        background: #151515;
        border: 1px solid #009de0;
        border-radius: 8px;
        padding: 8px 25px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #ffffff;
      }

      .dropdown-menu {
        background: #151515;
        border: 1px solid #292929;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        padding: 20px;
        width: 235px;
        // position: absolute;
        // transform: translate3d(-177px, 34px, 0px) !important;
        // top: 0px;
        // left: 0px;
        // will-change: transform;

        .input-field {
          label {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #ffffff;
          }

          .form-check-input {
            position: static !important;
            margin-top: 4px;
            margin-right: 8px;
            background-color: #151515;
            border: 2px solid #414141;
            border-radius: 4px;

            &:checked {
              background-color: #009de0;
              border: 2px solid #009de0;
              border-radius: 4px;
            }

            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}


.reduce-price {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  text-decoration-line: line-through;
  color: #E15151;
  display: block;
  position: absolute;
  top: 0;
  left: 0;

}

.suctsc {
  position: relative;
}