.Overview_odersec {
    // display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -17px;
    margin-bottom: 25px;

    .dropdown {
        margin-right: 10px;

        button {
            background: #151515;
            border: 1px solid #009DE0;
            border-radius: 8px;
            padding: 8px 25px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #FFFFFF;
        }

        .dropdown-menu {
            background: #151515;
            border: 1px solid #292929;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
            border-radius: 8px;
            padding: 20px;

            .input-field {
                label {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #FFFFFF;
                }

                .form-check-input {
                    position: static !important;
                    margin-top: 4px;
                    margin-right: 8px;
                    background-color: #151515;
                    border: 2px solid #414141;
                    border-radius: 4px;

                    &:checked {
                        background-color: #009DE0;
                        border: 2px solid #009DE0;
                        border-radius: 4px;
                    }

                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }
    }

    .ooddeerr {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 100% !important;
        color: #ffffff;
        white-space: nowrap;
    }

    button.oderFilter {
        background: #151515;
        border: 1px solid #292929;
        border-radius: 8px;
        display: flex;
        /* flex-direction: column; */
        align-items: center;
        padding: 8px 25px;
        grid-gap: 10px;
        gap: 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #ffffff;

        &:hover {
            color: #009de0;
            box-shadow: 0px 4px 4px #00000040;
            background-color: #ffffff3c;
        }
    }
    .parent-heading {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        h4 {
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: 100%;
          color: #ffffff;
          margin-bottom: 25px;
        }
        .dropdown {
          margin-right: 10px;
          button {
            background: #151515;
            border: 1px solid #009de0;
            border-radius: 8px;
            padding: 8px 25px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #ffffff;
          }
          .dropdown-menu {
            background: #151515;
            border: 1px solid #292929;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
            border-radius: 8px;
            padding: 20px;
            .input-field {
              label {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #ffffff;
              }
              .form-check-input {
                position: static !important;
                margin-top: 4px;
                margin-right: 8px;
                background-color: #151515;
                border: 2px solid #414141;
                border-radius: 4px;
                &:checked {
                  background-color: #009de0;
                  border: 2px solid #009de0;
                  border-radius: 4px;
                }
                &:focus {
                  box-shadow: none;
                }
              }
            }
          }
        }
      }
}

.fsttabeleflex {
    border: 1px solid #292929 !important;
    border-radius: 10px;
    margin-top: 25px;
    background: #212121 !important;
    margin-bottom: 30px;

    // width: 1085px;
    p.ridername {
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        // line-height: 100%;
        text-transform: uppercase;
        color: #767676;
        display: flex;
        align-content: flex-start;
        padding: 20px 20px;
        border-bottom: 1px solid #292929;
    }

    .assign_rider {
        display: flex;
        justify-content: space-between;
        padding: 20px 20px;
        text-align: center;
        align-content: center;
        align-items: center;
    }

    p.assigned {
        font-style: normal;
        font-weight: 400;
        font-size: 14px !important;
        // line-height: 100%;
        color: #4a4a4a;
    }

    button.btn_aassignrider {
        background: #009de0;
        border-radius: 5px;
        align-items: center;
        padding: 10px 15px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        // line-height: 100%;
        color: #ffffff;
        /* width: 117px; */
        white-space: nowrap;
        border: none;

        &:hover {
            color: #009de0;
            box-shadow: 0px 4px 4px #00000040;
            background-color: #ffffff;
        }
    }


}


.progressselling {
    background: #00ae11;
    border-radius: 30px;
    padding: 5px 10px;
    gap: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    // line-height: 100%;
    color: #ffffff;
}

p.fstrownumfourPrimary {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    // line-height: 100%;
    color: #009de0;
}

p.progressblue {
    background: #009de0;
    border-radius: 30px;
    padding: 5px 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    /* line-height: 100%; */
    display: inline;
    color: #ffffff;
}

.change-btn {
    background: #009de0;
    border-radius: 5px;
    padding: 10px 15px;
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #ffffff;
}

.table-responsive.odertablelast.mt-4.displaynone {
    display: none;
}

@media (max-width: 330px) {
    .fsttabeleflex .assign_rider {
        display: grid;
        /* justify-content: space-between; */
        // padding: 20px 20px;
        // text-align: center;
        // align-content: center;
        // align-items: center;
    }
}

// Assing Oder sidebar right side

.assignorder {
    --bs-offcanvas-width: 470px;

    .offcanvas-title {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 100%;
        color: #ffffff;
        margin: 0;
    }

    thead.bashbord_table_headdd {
        border-bottom: 1.5px solid #292929;

        tr.tableroww {
            font-style: normal;
            font-weight: 500;
            font-size: 14px !important;
            line-height: 100%;
            text-transform: uppercase;
            color: #767676;
        }
    }

    tr.fstrowtable {
        border-bottom: 1.5px solid #292929 !important;

        p.fstrownumsecss {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            // line-height: 100%;
            color: #ffffff;
            text-transform: capitalize !important;
        }

        span.greenprocessss {
            background: #00ae11;
            border-radius: 30px;
            padding: 5px 10px;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            // line-height: 100%;
            color: #ffffff;
            white-space: nowrap;
            text-transform: capitalize !important;
        }

        span.greenprocesss_red {

            background: #E15151;
            border-radius: 30px;
            padding: 5px 10px;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            text-transform: capitalize !important;
            color: #ffffff;
            white-space: nowrap;

        }

        button.tble_detailss {
            background: #009de0;
            border-radius: 8px;
            padding: 10px 15px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #ffffff;
            border: 1px solid #009de0;

            &:hover {
                color: #009de0;
                box-shadow: 0px 4px 4px #00000040;
                background-color: #fff;
                border: 1px solid #009de0;
            }
        }

        button.tble_detailss_Unavailable {
            background: #282828;
            border-radius: 8px;
            padding: 10px 15px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #515050;
            border: 1px solid transparent;
            // &:hover {
            //     color: #009de0;
            //     box-shadow: 0px 4px 4px #00000040;
            //     background-color: #fff;
            //     border: 1px solid #009de0;
            // }
        }
    }
}

.mainfoot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #212121;
    border-bottom: 1px solid #292929;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 14px;

    .right {
        .pagination {
            .page-link {
                background: transparent;
                border: none;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 100%;
                color: #757575;

                &:focus {
                    box-shadow: none;
                }

                &.active {
                    background: #009DE0;
                    border-radius: 5px;
                    color: #fff;
                }
            }
        }
    }

    .left {

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 37px;
            text-transform: uppercase;
            color: #767676;
        }
    }
}

@media(max-width:360px){
    .mainfoot .left p {
        font-size: 10px;
    }
}


.banktransfer-sidebar{
    .offcanvas-header{
        .offcanvas-title{
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 100%;
            color: #FFFFFF;
            margin: 0 !important;
        }
    }
    .reciept-img{
        max-width: 410px;
        width: 100%;
        height: 354px;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .btn-reject{
        background: #009DE0;
        border: none;
        border-radius: 8px;
        padding: 17px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #FFFFFF;
    }
    .twice-btn{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 238px;
        button{
            flex: 0 0 48%;
        }
      
        .btn-approve{
            background: #00AE11;
            border: none;
            border-radius: 8px;
            padding: 17px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #FFFFFF;
        }
    }
}

.twice-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .view-receipt{
        background: #009DE0;
        border-radius: 5px;
        border: none;
        padding: 10px 15px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #FFFFFF;
    }
}