.rider {
  .parent-heading {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 100%;
      color: #FFFFFF;
      margin-bottom: 25px;
    }

    .addcategory-btn {
      background: #009DE0;
      border-radius: 8px;
      padding: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #FFFFFF;
    }

    .right {
      display: flex;
      align-items: center;
    }

    .dropdown {
      margin-right: 10px;

      button {
        background: #151515;
        border: 1px solid #009DE0;
        border-radius: 8px;
        padding: 8px 25px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #FFFFFF;
      }

      .dropdown-menu {
        background: #151515;
        border: 1px solid #292929;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        padding: 20px;

        .input-field {
          label {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #FFFFFF;
          }

          .form-check-input {
            position: static !important;
            margin-top: 4px;
            margin-right: 8px;
            background-color: #151515;
            border: 2px solid #414141;
            border-radius: 4px;

            &:checked {
              background-color: #009DE0;
              border: 2px solid #009DE0;
              border-radius: 4px;
            }

            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  .table-responsive {
    background: #1E1E1E;
    border: 1px solid #292929;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    table {
      thead {
        background: #212121;
        border-bottom: 1px solid #292929;
      }

      th {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
        color: #767676 !important;
      }

      td {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF !important;
        border-top: 1px solid #292929 !important;
      }

      .id {
        color: #767676 !important;
      }
    }

    .btn-selling {
      background: #00AE11;
      border-radius: 30px;
      padding: 5px 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      color: #FFFFFF;
    }
  }





  // switch input scss here .......
  .tgl {
    display: none;

    // add default box-sizing for this scope
    &,
    &:after,
    &:before,
    & *,
    & *:after,
    & *:before,
    &+.tgl-btn {
      box-sizing: border-box;

      &::selection {
        background: none;
      }
    }

    +.tgl-btn {
      outline: 0;
      display: block;
      width: 37px;
      height: 20px;
      position: relative;
      cursor: pointer;
      user-select: none;

      &:after,
      &:before {
        position: relative;
        display: block;
        content: "";
        width: 50%;
        height: 100%;
      }

      margin-top: 17px;

      &:after {
        left: 0;
      }

      &:before {
        display: none;
      }
    }

    &:checked+.tgl-btn:after {
      left: 50%;
      background: #fbfbfb;
    }
  }

  // themes

  .tgl-ios {
    +.tgl-btn {
      background: #1E1E1E;
      border: 1px solid #5C5C5C;
      border-radius: 2em;
      padding: 2px;
      transition: all .4s ease;

      //   border: 1px solid #e8eae9;
      &:after {
        border-radius: 2em;
        background: #5C5C5C;
        transition:
          left .3s cubic-bezier(0.175, 0.885, 0.320, 1.275),
          padding .3s ease, margin .3s ease;
        box-shadow:
          0 0 0 1px rgba(0, 0, 0, .1),
          0 4px 0 rgba(0, 0, 0, .08);
      }

      &:hover:after {
        will-change: padding;
      }

      &:active {
        box-shadow: inset 0 0 0 2em #e8eae9;

        &:after {
          padding-right: .8em;
        }
      }
    }

    &:checked+.tgl-btn {
      background: #E15151;

      &:active {
        box-shadow: none;

        &:after {
          margin-left: -.8em;

        }
      }
    }
  }

  .stockout {
    background: #E15151 !important;
  }
}

Assing _ridder slider .update_staff {
  .offcanvas-title.h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    /* line-height: 100%; */
    color: #FFFFFF;
  }

  .offcanvas-body {
    .table-responsivess {

      .carddd {
        background: #1E1E1E;
        border: 1px dashed #313131;
        border-radius: 8px;
        width: 410px;
        height: 147px;

        // margin: 0px auto;
        .file-upload-wrapper {
          margin: 0px auto;
        }
      }

    }
  }
}


.update_staff {
  // padding: 34px 30px;
  width: 100%;

  .offcanvas-title {
    margin-top: 34px !important;
    margin-bottom: 33px !important;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 100%;
    color: #FFFFFF;
    margin: 0;

  }
}



.bannerss {
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 170%;
    display: flex;
    align-items: center;
    color: #009DE0;
    margin-bottom: 8px;
  }

  .list-inline-item {
    text-align: center;
  }

  .item-upload {
    background: #1E1E1E;
    border: 1px dashed #313131 !important;
    border-radius: 8px;
    padding: 35px;

    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 170%;
      display: flex;
      align-items: center;
      color: #FFFFFF;
      display: block;
      margin-top: 8px;
      margin-bottom: 5px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 170%;
      /* or 20px */
      display: flex;
      align-items: center;
      color: #6D6D6D;
    }
  }

  .img-content {
    margin-top: 20px;
  }

  .mainidcard {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .idcard {
      margin-top: 20px;

      .item-upload {
        background: #1E1E1E;
        border: 1px dashed #313131;
        border-radius: 8px;
        padding: 35px 10px;

        h4 {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 170%;
          display: flex;
          align-items: center;
          color: #FFFFFF;
          display: block;
          margin-top: 8px;
          margin-bottom: 5px;
        }
      }

    }
  }

  .buttonss {
    display: flex;

    button {
      background: #313131;
      border-radius: 8px;
      padding: 17px 12px;
      width: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #FFFFFF;
      white-space: nowrap;
      // margin-top: 25px;
      margin-bottom: 30px;
      border: none;

      &:hover {
        background: #009DE0;
      }
    }

  }
  
  .filedinput {
    margin-top: 20px;
    margin-bottom: 40px;
    .catagoryfield {
      position: relative;

      img {
        position: absolute;
        top: 20px;
        right: 18px;
      }
    }

    .productarea {
      background: #1E1E1E;
      border: 1px solid #313131;
      border-radius: 8px;
      padding: 15px 15px 15px 28px;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 170%;
      display: flex;
      align-items: center;
      color: #009DE0;
      margin-bottom: 8px;
    }

    input {
      background: #1E1E1E;
      border: 1px solid #313131;
      border-radius: 8px;
      padding: 15px;
      width: 100%;
      margin-bottom: 25px;
      color: #FFFFFF;
      // color: #525252;
    }
 
  }
}

.offcanvas-backdrop.show {
  background: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  opacity: 1;
}

@media (max-width:360px){
  .rider .parent-heading {
    flex-direction: column;
    margin-bottom: 10px;
  }
}

.rider .parent-heading .addcategory-btn{
  white-space: nowrap;
  max-width: 150px;
  width: 100%;
  text-align: center;
}