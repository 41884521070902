.brandmanagement {
    .img-profile {
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 50%;
    }

}
.table-customer {
    .table {
        .action {
            text-align: end;
            padding-right: 40px;
        }
    }
}
.images {
    float: right;
}

.sidebar-promoproduct {
    .upload-img {
        .upload {
            background: #1E1E1E;
            border: 1px dashed #313131;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 20px;
            height: 147px;
            overflow: hidden;

            h6 {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 170%;
                color: #FFFFFF;
                margin-top: 8px;
                margin-bottom: 5px;
            }

            span {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 170%;
                color: #6D6D6D;
            }
        }
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {

.promo .parent-heading {
    display: block;
}
.promo .customerdetailheading h3{
    font-size: 16px;
}
}