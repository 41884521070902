.ourstaff {
  overflow: hidden;
  .parent-heading {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 100%;
      color: #ffffff;
      margin-bottom: 25px;
    }
    .addcategory-btn {
      background: #009de0;
      border-radius: 8px;
      padding: 13px 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #ffffff;
    }
    .right {
      display: flex;
      align-items: center;
    }
    .dropdown {
      margin-right: 10px;
      button {
        background: #151515;
        border: 1px solid #009de0;
        border-radius: 8px;
        padding: 8px 25px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #ffffff;
      }
      .dropdown-menu {
        background: #151515;
        border: 1px solid #292929;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        padding: 20px;
        .input-field {
          label {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #ffffff;
          }
          .form-check-input {
            position: static !important;
            margin-top: 4px;
            margin-right: 8px;
            background-color: #151515;
            border: 2px solid #414141;
            border-radius: 4px;
            &:checked {
              background-color: #009de0;
              border: 2px solid #009de0;
              border-radius: 4px;
            }
            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  .table-responsive {
    background: #1e1e1e;
    border: 1px solid #292929;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    table {
      thead {
        background: #212121;
        border-bottom: 1px solid #292929;
      }
      .staff-pic{
        width: 100px;
        height: 100px;
      }

      th {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
        color: #767676 !important;
      }

      td {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff !important;
        border-top: 1px solid #292929 !important;
      }

      .id {
        color: #767676 !important;
      }
    }

    .btn-selling {
      background: #00ae11;
      border-radius: 30px;
      padding: 5px 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      color: #ffffff;
    }
  }

  // switch input scss here .......
  .tgl {
    display: none;

    // add default box-sizing for this scope
    &,
    &:after,
    &:before,
    & *,
    & *:after,
    & *:before,
    & + .tgl-btn {
      box-sizing: border-box;

      &::selection {
        background: none;
      }
    }

    + .tgl-btn {
      outline: 0;
      display: block;
      width: 37px;
      height: 20px;
      position: relative;
      cursor: pointer;
      user-select: none;

      &:after,
      &:before {
        position: relative;
        display: block;
        content: "";
        width: 50%;
        height: 100%;
      }

      margin-top: 17px;

      &:after {
        left: 0;
      }

      &:before {
        display: none;
      }
    }

    &:checked + .tgl-btn:after {
      left: 50%;
    }
  }

  // themes

  .tgl-ios {
    + .tgl-btn {
      background: #fbfbfb;
      border-radius: 2em;
      padding: 2px;
      transition: all 0.4s ease;

      //   border: 1px solid #e8eae9;
      &:after {
        border-radius: 2em;
        background: #fbfbfb;
        transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
          padding 0.3s ease, margin 0.3s ease;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
      }

      &:hover:after {
        will-change: padding;
      }

      &:active {
        box-shadow: inset 0 0 0 2em #e8eae9;

        &:after {
          padding-right: 0.8em;
        }
      }
    }

    &:checked + .tgl-btn {
      background: #00ae11;

      &:active {
        box-shadow: none;

        &:after {
          margin-left: -0.8em;
        }
      }
    }
  }

  .stockout {
    background: #e15151 !important;
  }
}

.updatestaff {
  padding-top: 30px;

  .offcanvas-title {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 100%;
    color: #ffffff;
    margin: 0;
  }

  .offcanvas-body {
    &::-webkit-scrollbar {
      display: none;
    }
    .main-form {
      .dropdownbtn {
        .dropdown {
          button {
            background: #1e1e1e;
            border: 1px solid #313131;
            border-radius: 8px;
            margin-top: 8px;
            padding: 15px;
            width: 100%;
            text-align: start;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .button.btn.btn-secondary.dropdown-toggle.button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1.5px solid #009de0;
          }
        }

        .dropup .dropdown-toggle:after,
        .dropdown .dropdown-toggle:after {
          display: none;
        }

        .dropdown-menu.show {
          background: #1e1e1e;
          border: 1px solid #292929;
          border-radius: 8px;
          width: 100%;
          padding: 15px 10px;

          .main-parent {
            position: relative;

            input {
              background: #1e1e1e;
              border: 1px solid #313131;
              border-radius: 8px;
              width: 100%;
              margin-top: 8px;
              padding: 15px;
              margin-bottom: 19px;
              padding-left: 45px;
              color: #ffffff;
            }

            img {
              position: absolute;
              top: 23px;
              left: 10px;
            }
          }

          .form-check-input {
            position: static !important;
            margin-top: 4px;
            margin-right: 8px;
            background-color: #151515;
            border: 2px solid #414141;
            border-radius: 4px;

            &:checked {
              background-color: #009de0;
              border: 2px solid #009de0;
              border-radius: 4px;
            }

            &:focus {
              box-shadow: none;
            }
          }

          .form-group {
            display: block;
            margin-bottom: 12px;
            border-bottom: 1px solid #292929;

            &:last-child {
              border-bottom: none;
            }
          }

          .form-group input {
            padding: 0;
            height: initial;
            width: initial;
            margin-bottom: 0;
            display: none;
            cursor: pointer;
          }

          .form-group label {
            position: relative;
            cursor: pointer;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: #ffffff;
          }

          .form-group label:before {
            content: "";
            -webkit-appearance: none;
            padding: 10px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 15px;

            background-color: #1e1e1e;
            border: 1px solid #313131;
            border-radius: 5px;
          }
        }
      }
      .checked-main {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        margin-top: 8px;

        .form-group {
          display: block;
          margin-bottom: 15px;
        }

        .form-group input {
          padding: 0;
          height: initial;
          width: initial;
          margin-bottom: 0;
          display: none;
          cursor: pointer;
        }

        .form-group label {
          position: relative;
          cursor: pointer;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 170%;
          display: flex;
          align-items: center;
          color: #ffffff;
        }

        .form-group label:before {
          content: "";
          -webkit-appearance: none;
          padding: 10px;
          display: inline-block;
          position: relative;
          vertical-align: middle;
          cursor: pointer;
          margin-right: 5px;

          background-color: #1e1e1e;
          border: 1px solid #313131;
          border-radius: 5px;
        }

        .form-group input:checked + label:before {
          background: #009de0;
          border-radius: 5px;
        }

        .form-group input:checked + label:after {
          content: "";
          display: block;
          position: absolute;
          top: 2px;
          left: 9px;
          width: 6px;
          height: 14px;
          border: solid #fff;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }

      .bannerss {
        .employeimg {
          border-radius: 5px;
          height: 112px;
          width: 112px;
        }

        h4 {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 170%;
          display: flex;
          align-items: center;
          color: #009de0;
          margin-bottom: 8px;
        }

        .list-inline-item {
          text-align: center;
        }

        .item-upload {
          background: #1e1e1e;
          border: 1px dashed #313131 !important;
          border-radius: 8px;
          padding: 35px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          h4 {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: #ffffff;
            display: block;
            margin-top: 8px;
            margin-bottom: 5px;
          }

          p {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 170%;
            /* or 20px */
            display: flex;
            align-items: center;
            color: #6d6d6d;
          }
        }

        .img-content {
          margin-top: 20px;
        }
      }

      .head {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: #009de0;
        margin-bottom: 8px;
        margin-top: 20px;
      }

      span {
        color: red;
      }

      input {
        background: #1e1e1e;
        border: 1px solid #313131;
        border-radius: 8px;
        padding: 15px;
        width: 100%;
        color: white;

        &::placeholder {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 120%;
          color: #525252;
        }
      }

      .box-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 17px 12px;
          width: 100%;
          background: #313131;
          border-radius: 8px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          color: #ffffff;
          border: none;

          &:hover {
            background: #009de0;
          }
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .ourstaff .parent-heading {
    flex-direction: column;
    margin-bottom: 10px;
  }
}

.ourstaff .parent-heading .addcategory-btn{
  white-space: nowrap;
}

@media (max-width:600px){
  .ourstaff .parent-heading{
    flex-direction: column;
  }
}