.product-ratelist {
    overflow: hidden;

    .parent-heading {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        h4 {
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: 100%;
          color: #ffffff;
          margin-bottom: 25px;
        }
        .dropdown {
          margin-right: 10px;
          button {
            background: #009de0;
            border: 1px solid #009de0;
            border-radius: 8px;
            padding: 15px 25px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #ffffff;
          }
          .dropdown-menu {
            background: #151515;
            border: 1px solid #292929;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
            border-radius: 8px;
            padding: 20px;
            .input-field {
              label {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #ffffff;
              }
              .form-check-input {
                position: static !important;
                margin-top: 4px;
                margin-right: 8px;
                background-color: #151515;
                border: 2px solid #414141;
                border-radius: 4px;
                &:checked {
                  background-color: #009de0;
                  border: 2px solid #009de0;
                  border-radius: 4px;
                }
                &:focus {
                  box-shadow: none;
                }
              }
            }
          }
        }
      }

    .main-tabsstyles {
        border: 1px solid #292929;
        border-radius: 8px;
        padding: 5px;
        gap: 5px;
        width: 444px;
        text-align: center;
        margin-bottom: 20px;

        div#nav-tab {
            border: none !important;
            text-align: center !important;

            .tabbuttons {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 26px;
                text-transform: capitalize;
                color: #ffffff;
                background: transparent;
                border-radius: 8px;
                align-items: center;
                padding: 7px 35px;
                gap: 10px;
                border: 2px solid transparent;

                &:hover {
                    border: 2px solid transparent;
                    background: #009de0;
                }
            }
        }
    }

    .table-responsive {
        background: #1e1e1e;
        border: 1px solid #292929;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        table {
            thead {
                background: #212121;
                border-bottom: 1px solid #292929;
            }

            th {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                text-transform: uppercase;
                color: #767676 !important;
            }

            td {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: #ffffff !important;
                border-top: 1px solid #292929 !important;
            }

            .id {
                color: #767676 !important;
            }
        }

        .btn-selling {
            background: #00ae11;
            border-radius: 30px;
            padding: 5px 10px;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 100%;
            color: #ffffff;
            text-align: center;
        }
    }

    // switch input scss here .......
    .tgl {
        display: none;

        // add default box-sizing for this scope
        &,
        &:after,
        &:before,
        & *,
        & *:after,
        & *:before,
        &+.tgl-btn {
            box-sizing: border-box;

            &::selection {
                background: none;
            }
        }

        +.tgl-btn {
            outline: 0;
            display: block;
            width: 37px;
            height: 20px;
            position: relative;
            cursor: pointer;
            user-select: none;

            &:after,
            &:before {
                position: relative;
                display: block;
                content: "";
                width: 50%;
                height: 100%;
            }

            margin-top: 17px;

            &:after {
                left: 0;
            }

            &:before {
                display: none;
            }
        }

        &:checked+.tgl-btn:after {
            left: 50%;
            background: #fbfbfb;
        }
    }

    // themes

    .tgl-ios {
        +.tgl-btn {
            background: #1e1e1e;
            border: 1px solid #5c5c5c;
            border-radius: 2em;
            padding: 2px;
            transition: all 0.4s ease;

            //   border: 1px solid #e8eae9;
            &:after {
                border-radius: 2em;
                background: #5c5c5c;
                transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
                box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
            }

            &:hover:after {
                will-change: padding;
            }

            &:active {
                box-shadow: inset 0 0 0 2em #e8eae9;

                &:after {
                    padding-right: 0.8em;
                }
            }
        }

        &:checked+.tgl-btn {
            background: #00ae11;

            &:active {
                box-shadow: none;

                &:after {
                    margin-left: -0.8em;
                }
            }
        }
    }

    .stockout {
        background: #e15151 !important;
    }
}

// Product edit sidebar scss here........
.sidebar-editproduct {
    width: 100%;

    .offcanvas-title {
        margin-top: 34px !important;
        margin-bottom: 33px !important;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 100%;
        color: #ffffff;
        margin: 0;
    }

    .bannerss {
        h4 {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: #009de0;
            margin-bottom: 8px;
        }

        .list-inline-item {
            text-align: center;
        }

        .item-upload {
            background: #1e1e1e;
            border: 1px dashed #313131;
            border-radius: 8px;
            padding: 35px;
            margin-bottom: 20px;

            h4 {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 170%;
                display: flex;
                align-items: center;
                color: #ffffff;
                display: block;
                margin-top: 8px;
                margin-bottom: 5px;
            }
        }
    }

    .filedinput {
        .catagoryfield {
            position: relative;

            img {
                position: absolute;
                top: 20px;
                right: 18px;
            }
        }

        .productarea {
            background: #1e1e1e;
            border: 1px solid #313131;
            border-radius: 8px;
            padding: 15px;
            height: 120px;
            color: #fff;
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: #009de0;
            margin-bottom: 8px;
        }

        input {
            background: #1e1e1e;
            border: 1px solid #313131;
            border-radius: 8px;
            padding: 15px;
            width: 100%;
            margin-bottom: 25px;
            color: #ffffff;
            // color: #525252;
        }
    }

    .buttonss {
        display: flex;

        button {
            background: #313131;
            border-radius: 8px;
            padding: 12px;
            width: 100%;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #ffffff;
            white-space: nowrap;
            // margin-top: 25px;
            margin-bottom: 10px;
            border: none;

            &:hover {
                background: #009de0;
            }
        }
    }

    .producttag {
        background: #1e1e1e;
        border: 1px solid #313131;
        border-radius: 8px;
        padding: 15px;
        display: flex;
        align-items: center;

        .babycare {
            background: #313131;
            border-radius: 5px;
            padding: 5px 10px;

            img {
                margin-left: 8px;
            }

            p {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                margin: 0px;

                color: #ffffff !important;
            }
        }
    }
}

.offcanvas.offcanvas-start {
    padding: 15px;

    .offcanvas-header {
        align-items: baseline;

        .offcanvas-title.h5 {
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 100%;
            color: #ffffff;
        }
    }

    .offcanvas-body {
        label.form-label {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 170%;
            /* display: flex; */
            align-items: center;
            color: #009de0;
        }

        .appleimmgss {
            text-align: center;


        }

        input#formGridEmail {
            background: #1e1e1e;
            border: 1px solid #313131;
            border-radius: 8px;
            width: 100%;
            height: 44px;
            color: #ffffff;
            outline: none !important;

            &::placeholder {
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                color: #ffffff;
            }
        }

        .head {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: #009de0;
            margin-bottom: 8px;
            margin-top: 20px;
        }

        .inputpara {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: #009de0;
            margin-bottom: 8px;
            margin-top: 20px;
        }

        .selected {
            background-color: #313131;
            color: #ffffff;
            min-width: 100px;
            max-width: 200px;
            padding: 10px;
            border-radius: 5px;
            margin: 10px 0;
            display: inline-block;
            margin-left: 5px;
      
         

            img {
                width: 10x;
                height: 10px;
                margin-left: 10px;
                color: red;
            }
        }
     
        .pres {
            display: flex;
            align-items: center; 
            color: #ffffff;
          
            input[type="checkbox"] {
              width: 20%;
              height: 20%;
            //   margin-right: 5px; 
            }
          
            label {
              width: 80%;
              margin-top: 10px;
              margin-left: -25px;
            }
          }
       
    }

    .ofcavlastbtn {
        display: flex;
        justify-content: space-evenly;

        button.offlastbtn.btn.btn-btn {
            background: #313131;
            border-radius: 8px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #ffffff;
            // width: 100%;
            padding: 12px 54px;
            width: 200.5px;
            height: 48px;
            margin-right: 10px;
        }

        button.offlastupdatebtn.btn.btn-btn {
            border-radius: 8px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #ffffff;
            // width: 100%;
            background: #009de0;
            padding: 12px 54px;
            width: 200.5px;
            height: 48px;
        }
    }
}

@media (max-width: 600px) {
    .product-ratelist .parent-headings {
        display: unset;
    }

    .product-ratelist .main-tabsstyles {
        border: 1px solid #292929;
        border-radius: 8px;
        padding: 5px;
        grid-gap: 5px;
        gap: 5px;
        width: unset;
        text-align: center;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .product-ratelist .main-tabsstyles div#nav-tab .tabbuttons {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 26px;
        text-transform: capitalize;
        color: #ffffff;
        background: transparent;
        border-radius: 8px;
        align-items: center;
        padding: 7px 20px;
        grid-gap: 10px;
        gap: 10px;
        border: 2px solid transparent;
    }
}