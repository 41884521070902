@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');

@import 'paper-dashboard/variables';
@import 'paper-dashboard/mixins';

// Plugins CSS
@import "paper-dashboard/plugins/plugin-animate-bootstrap-notify";
@import "paper-dashboard/plugins/plugin-perfect-scrollbar";

// Core CSS
@import "paper-dashboard/buttons";
@import "paper-dashboard/inputs";
@import "paper-dashboard/typography";
@import "paper-dashboard/misc";
@import "paper-dashboard/checkboxes-radio";


// components
@import "paper-dashboard/navbar";
@import "paper-dashboard/page-header";
// @import "paper-dashboard/dropdown";
// @import "paper-dashboard/alerts";
@import "paper-dashboard/images";
@import "paper-dashboard/nucleo-outline";
// @import "paper-dashboard/tables";
@import "paper-dashboard/sidebar-and-main-panel";
@import "paper-dashboard/footers";
@import "paper-dashboard/fixed-plugin";

// cards
@import "paper-dashboard/cards";
// @import "paper-dashboard/cards/card-plain";
// @import "paper-dashboard/cards/card-chart";
// @import "paper-dashboard/cards/card-user";
// @import "paper-dashboard/cards/card-map";
// @import "paper-dashboard/cards/card-stats";

@import "paper-dashboard/responsive";

// html to react differences
@import "paper-dashboard/react/react-differences.scss";
