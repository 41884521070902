.upload-csv-wrapper {
    h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 170%;
        align-items: center;
        color: #009DE0;
        margin-bottom: 8px;
    }

    .upload-csv-box {
        width: 100%;
        height: 147px;
        background: #1E1E1E;
        border: 1px dashed #313131;
        border-radius: 8px;

        .upload-csv-box-data {
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            h5 {
                color: white;
            }
        }
    }
}


// DiscountDetailModal styling ==========================
.DiscountDetailModal {
    width: 500px;

    table {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        text-transform: uppercase;
        color: #767676;
    }
}

// .discount-Detail-table-inner-wrapper {
//     border-radius: 14px;
//     border-bottom: 1px solid #292929;
// }


.discount-Detail-table tbody tr:nth-child(odd) td {
    padding-bottom: 5px;
    border-top: 1px solid #313131 !important;
    border-top-width: 1px !important;
}

.discount-Detail-table {
    // border: 1px solid red;
    border-collapse: unset;
    // background: #212121;
    // margin: 0px;
    // border-radius: 14px;


    border: 1px solid #313131;
    border-radius: 14px;
    border-spacing: 0px;


    thead {
        tr {
            th {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                text-transform: uppercase;
                color: #767676;
            }
        }
    }


    tbody {
        tr {
            td {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                text-transform: uppercase;
                color: #767676;

                .images {
                    float: none;

                    .img-fluid {
                        width: 30px;
                        height: 30px;
                        border-radius: 100%;
                        cursor: pointer;
                    }
                }
            }

            .item-detail-column {
                display: flex;
                align-items: center;

                p {
                    margin-top: 0px;
                    margin-bottom: 0px;
                    margin-left: 10px;
                    color: white;
                }
            }

            .detailbutton {
                text-align: right;
                padding-right: 0.75rem;

                span {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 100%;
                    /* identical to box height, or 14px */
                    text-align: right;
                    color: #009DE0;
                }
            }
        }

        .item-detail-row {

            td {
                // padding-top: 0px;
                padding-bottom: 5px;
                // border-bottom: 1px solid #313131;
                // border-bottom-width: 1px !important;

                div {
                    display: flex;
                    align-items: center;

                    .item-detail-row-data-dark {
                        color: #767676;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 100%;
                        margin-right: 8px;
                        margin-top: 5px;
                    }

                    .item-detail-row-data-light {
                        color: white;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 100%;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}

// DiscountDetailModal styling ==========================