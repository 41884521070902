.customerdetail {
  .customerdetailheading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 100%;
      color: #ffffff;
    }

    .backbutton {
      button {
        background: #e15151;
        border-radius: 5px;
        padding: 12px 15px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #ffffff;
        border: none;
      }
    }
  }

  .maincardcustomer {
    background: #1e1e1e;
    border: 1px solid #292929;
    border-radius: 10px;
    padding: 35px 40px;
    display: flex;
    // justify-content:center;
    align-items: center;
    margin-bottom: 25px;

    .imgcard {
      // width: 20%;
      width: 95px;
      height: 95px;

      img{
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }

    .lefttextdetail {
      margin-left: 20px;
      margin-top: 20px;
      // width: 20%;

      h6 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: #ffffff !important;
        margin-bottom: 25px;
        white-space: nowrap;

        &:last-child {
          margin-bottom: 0px;
        }
      }

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #767676;
        margin-bottom: 5px;
        white-space: nowrap;
      }
    }

    .centertextdetail {
      margin-left: 40px;
      margin-top: 20px;
      // width: 20%;

      h6 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: #ffffff !important;
        margin-bottom: 25px;

        &:last-child {
          margin-bottom: 0px;
        }
      }

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #767676;
        margin-bottom: 5px;
      }
    }

    .righttextdetail {
      margin-left: 40px;
      margin-top: 20px;
      // width: 40%;

      h6 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: #ffffff !important;
        margin-bottom: 25px;

        &:last-child {
          margin-bottom: 0px;
        }
      }

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #767676;
        margin-bottom: 5px;
      }
    }
  }
  .table-customer {
    background: #1e1e1e;
    border: 1px solid #292929;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow-x: scroll;
    th {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      text-transform: uppercase;
      color: #767676 !important;
      padding: 20px;
      border-bottom: unset !important;
    }
  }
  tbody {
    .idaff {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase;
      color: #767676 !important;
    }
    tr td {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #ffffff !important;
      border-top: 1px solid #292929 !important;
      padding: 20px 20px;
    }
  }

  .button-pro {
    .greenprocesss {
      background: #009de0;
      border-radius: 30px;
      padding: 5px 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px !important;
      line-height: 100% !important;
      color: #ffffff;
      width: 86px;
    }
    .greenprocesssgreen {
      background: #00ae11;
      border-radius: 30px;
      padding: 5px 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 100% !important;
      color: #ffffff;
    }
    .greenprocesssyellow {
      background: #ffb800;
      border-radius: 30px;
      padding: 5px 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 100% !important;
      color: #ffffff;
    }
    .greenprocesssred {
      background: #e95842;
      border-radius: 30px;
      padding: 5px 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 100% !important;
      color: #ffffff;
    }
    // background: #009de0;
    // border-radius: 30px;
    // padding: 5px 10px;
    // font-weight: 500;
    // font-size: 14px;
    // line-height: 100%;
    // color: #ffffff;
    // border: none;
  }
  .deliverbtn {
    background-color: #00ae11;
  }
  .pendingbtn {
    background: #ffb800;
  }
  .canclebtn {
    background-color: #e15151;
  }

  .buttonproduct {
    .dropdown-menu {
      background: #1e1e1e !important;
      color: #ffffff;
      .action {
        color: white;
        padding: 10px 5px;
        line-height: 0px !important;
      }
    }
    .buttondrop {
      background: #1e1e1e;
      border: 1px solid #292929;
      border-radius: 10px;
      padding: 10px 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #525252;
      width: 100px;
    }
    .arrowdown {
      margin-left: 15px;
    }
  }
  .productbutton {
    button {
      background: #009de0;
      border-radius: 5px;
      padding: 10px 15px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #ffffff;
      border: none;
      // margin-left: 52px;
    }
  }
  .gftyftdt a {
    line-height: 25px !important;
  }
}
@media (max-width: 600px) {
  .customerdetail .maincardcustomer .righttextdetail {
    margin-left: 0px;
    text-align: center;
  }
  .customerdetail .maincardcustomer .lefttextdetail {
    margin-left: 0px !important;
    text-align: center;
  }
  .customerdetail .maincardcustomer .centertextdetail {
    margin-left: 0px !important;
    text-align: center;
  }
  .customerdetail .maincardcustomer .lefttextdetail p {
    margin-bottom: 10px;
  }
  .customerdetail .maincardcustomer .centertextdetail p {
    margin-bottom: 10px;
  }
  .customerdetail .maincardcustomer .righttextdetail p {
    margin-bottom: 10px;
  }
  .customerdetail .maincardcustomer {
    flex-direction: column;
  }
  .content .table > thead > tr > th {
    padding: 20px 15px !important;
  }
  .content .table > tbody > tr > td {
    padding: 20px 15px !important;
  }
  .customerdetail .customerdetailheading {
    flex-direction: column;
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start;
  }
  .customerdetail .customerdetailheading .backbutton button {
    margin-top: 15px;
  }
}

.product_list {
  --bs-offcanvas-width: 470px;

  table.table {
    background: #212121;
    border-bottom: 1px solid #292929;
  }
  td.idtotalamt {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 100%; */
    text-transform: uppercase;
    color: #ffffff;
  }
  td.pprice {
    font-style: normal;
    font-weight: 700;
    font-size: 14px !important;
    /* line-height: 100%; */
    color: #009de0 !important;
  }
  .productbtn {
    /* justify-content: center; */
    text-align: center;
    button.productcanacel {
      background: #313131;
      border-radius: 8px;
      padding: 12px 54px;
      gap: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #ffffff;
      border: 2px solid transparent;
      width: 100%;
    }
  }
  .lastflex {
    display: flex;
    justify-content: space-between;
    background: #1e1e1e;
    border: 1px solid #292929;
    border-radius: 10px;
    padding: 10px;
    margin-top: -19px;
    margin-bottom: 25px;
    padding-top: 29px;
    padding-bottom: 25px;
    p.idtotalamt {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 100%;
      text-transform: uppercase;
      color: #ffffff;
    }
    p.idtotalamt2 {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 100%;
      color: #009de0;
    }
  }
  .offcanvas-title {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 100%;
    color: #ffffff;
    margin: 0;
  }
  thead.bashbord_table_headdd {
    border-bottom: 1.5px solid #292929;
    tr.tableroww {
      font-style: normal;
      font-weight: 500;
      font-size: 14px !important;
      line-height: 100%;
      text-transform: uppercase;
      color: #767676;
    }
  }
  tr.fstrowtable {
    border-bottom: 1.5px solid #292929 !important;
    p.ffreshmaker {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      /* line-height: 100%; */
      color: #ffffff;
    }
    .tablerow {
      border-bottom: 1px solid #292929 !important;
    }

    span.greenprocessss {
      background: #00ae11;
      border-radius: 30px;
      padding: 5px 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      // line-height: 100%;
      color: #ffffff;
      white-space: nowrap;
      text-transform: capitalize !important;
    }
    span.greenprocesss_red {
      background: #e15151;
      border-radius: 30px;
      padding: 5px 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      text-transform: capitalize !important;
      color: #ffffff;
      white-space: nowrap;
    }
    button.tble_detailss {
      background: #009de0;
      border-radius: 8px;
      padding: 10px 15px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #ffffff;
      border: 1px solid #009de0;
      &:hover {
        color: #009de0;
        box-shadow: 0px 4px 4px #00000040;
        background-color: #fff;
        border: 1px solid #009de0;
      }
    }
    button.tble_detailss_Unavailable {
      background: #282828;
      border-radius: 8px;
      padding: 10px 15px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #515050;
      border: 1px solid transparent;
      // &:hover {
      //     color: #009de0;
      //     box-shadow: 0px 4px 4px #00000040;
      //     background-color: #fff;
      //     border: 1px solid #009de0;
      // }
    }
  }
}
