.product {
  overflow: hidden;
  .parent-heading {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 100%;
      color: #ffffff;
      margin-bottom: 25px;
    }
    .dropdown {
      margin-right: 10px;
      button {
        background: #151515;
        border: 1px solid #009de0;
        border-radius: 8px;
        padding: 8px 25px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #ffffff;
      }
      .dropdown-menu {
        background: #151515;
        border: 1px solid #292929;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        padding: 20px;
        .input-field {
          label {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #ffffff;
          }
          .form-check-input {
            position: static !important;
            margin-top: 4px;
            margin-right: 8px;
            background-color: #151515;
            border: 2px solid #414141;
            border-radius: 4px;
            &:checked {
              background-color: #009de0;
              border: 2px solid #009de0;
              border-radius: 4px;
            }
            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  .table-responsive {
    background: #1e1e1e;
    border: 1px solid #292929;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    table {
      thead {
        background: #212121;
        border-bottom: 1px solid #292929;
      }

      th {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
        color: #767676 !important;
      }

      td {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff !important;
        border-top: 1px solid #292929 !important;

        img {
          width: 30px;
          height: 30px;
          border-radius: 100%;
        }
      }

      .id {
        color: #767676 !important;
      }
      .text-caps {
        text-transform: capitalize;
      }
    }

    .btn-selling {
      background: #00ae11;
      border-radius: 30px;
      padding: 5px 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      color: #ffffff;
      text-align: center;
    }
  }

  // switch input scss here .......
  .tgl {
    display: none;

    // add default box-sizing for this scope
    &,
    &:after,
    &:before,
    & *,
    & *:after,
    & *:before,
    & + .tgl-btn {
      box-sizing: border-box;

      &::selection {
        background: none;
      }
    }

    + .tgl-btn {
      outline: 0;
      display: block;
      width: 37px;
      height: 20px;
      position: relative;
      cursor: pointer;
      user-select: none;

      &:after,
      &:before {
        position: relative;
        display: block;
        content: "";
        width: 50%;
        height: 100%;
      }

      margin-top: 17px;

      &:after {
        left: 0;
      }

      &:before {
        display: none;
      }
    }

    &:checked + .tgl-btn:after {
      left: 50%;
      background: #fbfbfb;
    }
  }

  // themes

  .tgl-ios {
    + .tgl-btn {
      background: #1e1e1e;
      border: 1px solid #5c5c5c;
      border-radius: 2em;
      padding: 2px;
      transition: all 0.4s ease;

      //   border: 1px solid #e8eae9;
      &:after {
        border-radius: 2em;
        background: #5c5c5c;
        transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
          padding 0.3s ease, margin 0.3s ease;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
      }

      &:hover:after {
        will-change: padding;
      }

      &:active {
        box-shadow: inset 0 0 0 2em #e8eae9;

        &:after {
          padding-right: 0.8em;
        }
      }
    }

    &:checked + .tgl-btn {
      background: #00ae11;

      &:active {
        box-shadow: none;
        &:after {
          margin-left: -0.8em;
        }
      }
    }
  }
  .stockout {
    background: #e15151 !important;
  }
}

// Product edit sidebar scss here........
.sidebar-editproduct {
  width: 100%;

  .offcanvas-title {
    margin-top: 34px !important;
    margin-bottom: 33px !important;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 100%;
    color: #ffffff;
    margin: 0;
  }
  .bannerss {
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 170%;
      display: flex;
      align-items: center;
      color: #009de0;
      margin-bottom: 8px;
    }

    .list-inline-item {
      text-align: center;
    }

    .item-upload {
      background: #1e1e1e;
      border: 1px dashed #313131;
      border-radius: 8px;
      padding: 35px;
      margin-bottom: 20px;

      h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: #ffffff;
        display: block;
        margin-top: 8px;
        margin-bottom: 5px;
      }
    }
  }

  .filedinput {
    .catagoryfield {
      position: relative;

      img {
        position: absolute;
        top: 20px;
        right: 18px;
      }
    }

    .productarea {
      background: #1e1e1e;
      border: 1px solid #313131;
      border-radius: 8px;
      padding: 15px !important;
      height: 120px;
      color: #fff;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 170%;
      display: flex;
      align-items: center;
      color: #009de0;
      margin-bottom: 8px;
    }

    input {
      background: #1e1e1e;
      border: 1px solid #313131;
      border-radius: 8px;
      padding: 15px;
      width: 100%;
      margin-bottom: 25px;
      color: #ffffff;
      // color: #525252;
    }
  }
  .buttonss {
    display: flex;
    button {
      background: #313131;
      border-radius: 8px;
      padding: 12px;
      width: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #ffffff;
      white-space: nowrap;
      // margin-top: 25px;
      margin-bottom: 10px;
      border: none;
      &:hover {
        background: #009de0;
      }
    }
  }
  .producttag {
    .tags-input-container {
      // border: 2px solid #000;
      padding: 0.5em;
      border-radius: 3px;
      // width: min(80vw, 600px);
      margin-top: 1em;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.5em;
    }

    .tag-item {
      // background-color: rgb(218, 216, 216);
      display: inline-block;
      // padding: 0.5em 0.75em;
      // border-radius: 20px;
    }
    .tag-item .close {
      height: 20px;
      width: 20px;
      background-color: rgb(48, 48, 48);
      color: #fff;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-left: 0.5em;
      font-size: 18px;
      cursor: pointer;
    }

    .tags-input {
      flex-grow: 1;
      padding: 0.5em 0;
      border: none;
      outline: none;
    }

    background: #1e1e1e;
    border: 1px solid #313131;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    align-items: center;

    .babycare {
      background: #313131;
      border-radius: 5px;
      padding: 5px 10px;
      img {
        margin-left: 8px;
      }
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        margin: 0px;

        color: #ffffff !important;
      }
    }
  }
  .dropdown {
    .btn {
      background: #1e1e1e;
      border: 1px solid #313131;
      border-radius: 8px;
      width: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #525252;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      &:focus {
        box-shadow: none;
      }
    }

    .dropdown-menu {
      width: 100%;
      background: #1e1e1e;
      border: 1px solid #313131;
      border-radius: 8px;
      color: white;
      max-height: 500px;
      overflow-y: scroll;
    }
  }
}

.itemsssss {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-field {
    margin-right: 15px;
    position: relative;
    .parent-input {
      background: #151515;
      border: 1px solid #292929;
      border-radius: 8px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #fff;
      padding: 13px;
      padding-left: 49px;
      width: 100%;
      &::placeholder {
        color: #5b5b5b;
      }
    }
    .search-icon {
      position: absolute;
      top: 11px;
      left: 15px;
    }
  }
  .dropdown-text-parent {
    position: absolute;
    top: 18px;
    right: 5px;
  }
  .dropdown-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #ffffff;
    white-space: nowrap;
  }
}

@media (max-width: 600px) {
  .product .parent-heading {
    flex-direction: column;
  }
  .itemsssss .search-field input {
    max-width: 200px;
  }
  .itemsssss {
    margin-bottom: 25px;
  }
}
