.main-text {
    color: #fff;
    font-size: 22px;
    line-height: 2px;
    font-weight: 600;
    margin-top: 25px;
    margin-left: 20px;

}

.charges-main {
    background-color: #212121;
    margin-top: 25px;
    margin-left: 20px;
    border-radius: 10px;
    padding-bottom: 33px;

    h3 {
        color: #009DE0;
        font-size: 14px;
        line-height: 23.8px;
        font-weight: 500;
        padding-top: 40px;
        padding-left: 20px;
    }
.updatee {
    
    padding-bottom: 53px;
    input {
        width: 510px;
        border: 1px solid #313131;
        margin-left: 20px;
        margin-top: 8px;
        border-radius: 8px;
        padding: 15px;
        background: none;
        color: #fff;
        
    }

    input::placeholder {
        color: #fff;
    }
   
}
button {
    color: #fff;
    background-color: #009DE0;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    padding: 15px 57.5px;
    border-radius: 8px;
    margin: 10px 0 0 15px;
    border: none;

}

}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
 {
    .main-text {
        font-size:20px !important;
    }
    .charges-main {
        margin-left: 0;
    }
    .charges-main .updatee button {
        padding: 10px 35px;
        margin-right: 10px;
    }
}

@media only screen 
  and (min-device-width: 320px) 
  
 {
    .main-text {
        font-size:20px !important;
    }
    .charges-main {
        margin-left: 0;
    }
    .charges-main .updatee button {
        padding: 10px 35px;
        margin-right: 10px;
    }
}